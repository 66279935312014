@import '_variables';

.create-stylesheet {
    [disabled] {
        pointer-events: none;
        opacity: .5;
    }
    .radio {
        label {
            text-transform: none;
            font-weight: bold;
            font-size: @tidy-font-size;
            .help-block {
                font-weight: normal;
            }
        }
    }
    .input-group-addon {
        &.small {
            font-size: @tidy-font-size-sm;
        }
    }
}

.reference-list {
    position: relative;

    .clear-filter {
        position: absolute;
        right: 18px;
        padding: 12px 10px;
        color: #8a8a8a;
        cursor: pointer;
    }

    &-table {
        max-height: 50vh;
        overflow: auto;
        display: block;
        margin-bottom: 0;
        //border: 1px solid gray;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, .1);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.layout-container .layout-item.layout-side .side-info.reference-list {
    padding: 4px;
}
