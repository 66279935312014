@import '_variables';

&.layout-nav {
  flex-direction: column;
  order: -1;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #f5f5f5;
  box-shadow: inset -30px 0 30px -30px rgba(0, 0, 0, .1);
  overflow: visible;

  .save-publish-menu {

    .status {
      margin: 0 0 14px;
      border-radius: 0;
      border: 0;
      display: inline-block;
      width: 100%;
      padding: 34px 0;
      white-space: normal;
      position: relative;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 16px;
      color: @tidy-dark-gray;
      border-bottom: 1px solid rgba(0, 0, 0, .1);

      .btn-text {
        position: absolute;
        top: 50%;
        left: 8px;
        right: 8px;
        transform: translateY(-50%);

        .indicator {
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          transition: background-color .1s ease;

          &.saving {
            background-color: transparent;
            animation-name: spin;
            animation-duration: 350ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }

        small {
          color: @tidy-gray;
        }

      }
    }

    .save-panel-position {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0;
    }

    .save-panel {
      position: absolute;
      top: 0;
      left: 0;
      width: 250px;
      padding-left: 16px;
      padding-right: 16px;
      background: white;
      border-bottom-right-radius: 4px;
      box-shadow: 0 10px 56px rgba(0, 0, 0, .3);
      z-index: 10;
      max-height: 100%;
      overflow: auto;

      .btn-row {
        margin-top: 16px;
      }

      .btn-close {
        float: right;
        margin-right: -16px;
      }

      .indicator {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 50%;

        &.unpublished {
          background-color: @tidy-unpublished;
        }

        &.unsaved {
          background-color: @tidy-unsaved;
        }

        &.deleted {
          background-color: @tidy-deleted;
        }

      }

      .help {
        display: block;
        font-size: 12px;
        margin-left: 16px;
        margin-top: 4px;
        text-transform: none;
      }

      h3 {
        margin-top: 16px;
      }

      hr {
        border-top: 1px solid @tidy-border-color;
        margin: 16px -16px;
      }

      ul {
        list-style-type: disc;
        margin-left: 32px;
        margin-bottom: 16px;

        li {
          cursor: pointer;

          &.active {
            font-weight: 500;
          }

          &:hover {
            padding-left: 1px;
            transition: padding .2s;
          }

        }
      }

      .site-url {
        display: block;
        padding: 0;
        word-wrap: break-word;
        margin-bottom: 32px;
        text-align: center;
        font-size: @tidy-font-size-sm;
      }

    }

    &.unsaved {
      .indicator {
        background-color: @tidy-highlight;
        &.saving {
          box-shadow: 0 1px 0 0 @tidy-highlight;
        }
      }
    }

    &.unpublished {
      .indicator {
        background-color: @tidy-unpublished;
        &.saving {
          box-shadow: 0 1px 0 0 @tidy-unpublished;
        }
      }
    }

    &.all-done {

      .status {
        color: @tidy-dark-gray;

        .indicator {
          margin-top: 7px;
          //background-color: @tidy-version-green;
          background-color: @tidy-gray;

          opacity: 0.4;
          transition: opacity .1s ease;
        }
        .status-text {
          opacity: 0;
          transition: opacity .3s ease;
        }
        &:hover {
          .btn-text > div .indicator {
            opacity: 1;
          }
        }
      }

    }

  }

  .button-container {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {

    .nav-item {
      margin: 8px 0;
      border-radius: 6px;
      display: block;
      padding: 5px 0 8px;
      text-align: center;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 12px;
      // Chrome 54 fix
      // color: @tidy-rich-black;
      // opacity: 0.4;
      // transition: opacity .1s ease;
      color: @tidy-gray;

      .icon {
        display: block;
        height: 38px;
        line-height: 40px;
        font-size: 22px;
      }

      [class^="tidyicon-"],
      [class*=" tidyicon-"] {
        font-size: 28px;
      }

      .text {
        display: block;
        opacity: 0;
        transition: opacity .3s ease;
        width: 80px;
        padding-left: 8px;
        padding-right: 8px;
      }

      &.active {
        opacity: 1;
      }

    }

    .nav-item:hover {
      // Chrome 54 fix
      //opacity: 0.8;
      color: @tidy-rich-black;
    }

    a.v-link-active {
      opacity: 1;
      position: relative;
      color: @tidy-brand;
      &:after {
        transform: translateX(0px);
      }
    }

    .btn-group {
      width: 100%;

      .dropdown-link-icon {
        display: flex;
      }

      .dropdown-toggle {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        box-shadow: none;
      }

      .dropdown-menu {
        left: 24px;
      }

      .nav-profile {
        width: 40px;
        height: 40px;
        box-shadow: @tidy-box-shadow;
        cursor: pointer;
      }

      .nav-profile-letters {
        background: @tidy-brand;
        border-radius: 3px;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 0.1em;
        text-align: center;
        width: 40px;
        margin: 0 auto 16px;
        height: 40px;
        line-height: 40px;
        box-shadow: @tidy-box-shadow;
        color: white;
        cursor: pointer;
      }

    }

  }

  &:hover {
    li .text,
    .save-publish-menu.all-done .btn.status .status-text,
    .save-publish-menu.all-done .btn.status .indicator {
      opacity: 0.8;
    }
  }

  .modal {

    a:hover {
      color: @tidy-rich-black;
      text-decoration: underline;
    }

    .description {
      display: none;
    }

    .btn-row {
      margin-top: 20px;
    }

  }

}
