@import '_variables';

.parent-and-slug {
  position: relative;

  .parent-page {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;

    &.minimize {
      max-width: 64px;
    }

  }

}

.panel-in-modal .parent-and-slug .minimize {
  max-width: 256px;
}

.style-color,
.field-color {
  .input-group-addon {
    cursor: pointer;
    border-color: @tidy-light-gray;

    input {
      height: 0;
      width: 0;
      opacity: 0;
      cursor: pointer;
    }

  }
}

.style-spacing,
.field-quattro {
  position: relative;
  height: 72px;
  border: 1px solid @tidy-light-gray;
  margin: 16px 32px;

  .input-wrap {
    background-color: white;
    padding: 6px;
    position: absolute;
    width: 80px;
    box-sizing: border-box;
  }

  .form-control {
    text-align: center;
  }

  .top {
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
  }

  .right {
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
  }

  .bottom {
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
  }

  .left {
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
  }

  &.corner {

    .top {
      top: -22px;
      left: -40px;
      transform: none;
    }

    .right {
      top: -22px;
      right: -40px;
      transform: none;
    }

    .bottom {
      bottom: -22px;
      right: -40px;
      left: auto;
      transform: none;
    }

    .left {
      top: auto;
      bottom: -22px;
      left: -40px;
      transform: none;
    }

  }

}

.field-google-fonts {

  .select-font-group {
    padding: 0;
    margin-bottom: 6px;
    height: auto;
    max-height: 25vh;
    overflow-y: auto;

    .select-font {
      padding: 8px 10px;
      font-size: @tidy-font-size-lg;
      cursor: pointer;

      &:hover {
        background-color: @tidy-background-light-gray;
      }

    }

    &.open {
      .select-font {
        padding: 8px 16px 8px 20px;

        .icon {
          margin-left: -20px;
          width: 20px;
          text-align: center;
          font-size: @tidy-font-size-sm;
        }

      }

    }

  }

}

.field-with-dropdown {

  .dropdown-toggle {
    cursor: pointer;
    position: relative;

    .triangle {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 9px solid white;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      z-index: 4;
    }

  }

  .dropdown-position {
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;

    .dropdown {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: white;
      border-radius: 4px;
      box-shadow: @tidy-box-shadow-lg;
      padding: 16px;
      max-height: 400px;
      overflow-y: auto;
      z-index: 3;


      .dropdown-select {
        display: block;
        cursor: pointer;
        padding: 8px 0;
      }

    }

  }

}

.field-datepicker {

  .col-md-6 {
    width: 100%;
  }

  .btn {
    display: inline;
  }

}
