@import '_variables';

@keyframes loaderImageSpinHorizontal {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(360deg);
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: fade(white, 75%);
  z-index: 2147483647;

  &.no-opacity {
    background-color: white;
  }

  .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .message {
      text-align: center;
      margin-top: -50px;
      margin-bottom: 50px;
    }

    .flash {
      width: 90px;
      margin: 0 auto;
      animation: flashAnimation 0.8s linear infinite;
      transform-style: preserve-3d;

      &.stop {
        animation: none;
      }
    }

  }

  .whitelabel-loader {
    img {
      position: fixed;
      top: 50%;
      left: 50%;
      /* bring your own prefixes */
      transform: translate(-50%, -50%);
      max-width: 300px;
      max-height: 300px;
      animation: loaderImageSpinHorizontal 0.8s linear infinite;
      transform-style: preserve-3d;
    }
  }

}
