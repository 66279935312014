@import (reference) "../../../node_modules/bootstrap/less/mixins";
@import (reference) "../../cms/less/_bootstrap-extend";
@import (reference) "../../cms/less/_variables";


// ------------------------------------
// Dashboard overrides
// ------------------------------------

.create-site.dashboard {

  h3 {
    font-size: 1.4em;
    margin: 0.8em 0 1.5em;
    color: @gray-light;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 300;
    color: @gray-light;
    font-size: 1em;
    border-bottom: 1px solid @gray-lighter;
    padding-bottom: 0.6em;
    &:not(:first-child) {
      margin-top: 2.2em;
    }
  }

  .modal {
    h4 {
      margin-top: auto;
    }
  }

  .templates {
    margin-top: 90px;
    // Temporary modal fix - start
    opacity: 1;
    animation: none;
    // Temporary modal fix - end

    .dashboard-tools {
      text-align: right;
      .dashboard-create {
        border: 0;
        margin-right: 11px;
      }
    }

    .team-box {

      .clearfix;
      margin-top: 20px;
      padding-bottom: 40px;
      min-height: 200px;

      &:nth-child(even) {
        border-bottom: 1px solid @tidy-light-gray;
      }

      &__top {

        .clearfix;
        margin-bottom: 20px;

        h1 {
          text-align: left;
          float: left;
        }
      }

      &__filter {

        float: left;
        margin-left: 10px;
        max-width: 400px;

      }

      &__tools {
        float: right;

        .team-settings {

          .btn {
            color: @tidy-gray;
            span {
              padding-right: 7px;
            }
            .icon {
              color: @tidy-gray;
            }
            &:hover {
              color: @tidy-link-color-hover;
            }
          }

          .open-team-settings {
            display: none;
          }

          &:hover {
            .team-users {
              display: none;
            }
            .open-team-settings {
              display: inline;
            }
            .dropdown-menu {
              display: block;
              li {
                color: @tidy-gray;
                padding-left: 10px;
                padding-right: 10px;
                white-space: nowrap;
                .icon {
                  padding-right: 7px;
                }
              }
            }
          }

        }

        .filter-published {
          .btn {
            color: @tidy-gray;
          }
        }

      }

    }

    .templates__card {

      .templates__card__content {
        padding: 2px;
        border-radius: 0;

        .templates__card__image {
          height: 180px;
        }

        .templates__card__name {
          margin: 10px 0 0 10px;
        }

        .fa-plus {
          height: 180px;
          font-size: 130px;
          color: white;
          background: @tidy-brand;
          width: 100%;
          padding-top: 30px;
          opacity: 0.2;
        }

        &:after {
          display: none;
        }

      }

      &:hover,
      &:focus {
        text-decoration: none;

        .templates__card__content {
          transform: none;
        }

        .overlay {
          transform: translateY(0);
        }

      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: fade(white, 90%);
        transform: translateY(100%);
        transition-property: transform;
        transition-duration: .4s;
        transition-timing-function: @tidy-ease-heavy-in;

        .site-config {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 18px;
          color: fade(@tidy-brand, 50%);
          cursor: pointer;
          &:hover {
            color: @tidy-brand;
          }
        }

        .buttons {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 30px;
          right: 30px;

          .btn-row {
            margin-bottom: 0;

            .btn {
              margin-top: 10px;
              margin-bottom: 10px;
            }

          }

        }

      }

    }

  }

  .team-modal {

    //.modal-dialog {
    //  @media (min-width: @screen-desktop) {
    //    width: 800px;
    //  }
    //}

    .user-table {

      .user-row {
        &.removed {
          td {
            text-decoration: line-through;
          }
        }
        td.email {
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

  }

}
