@import '_variables';

.tree {
    @tree-line-height: 32px;

    padding-left: 8px;
    padding-right: 8px;
    list-style: none;
    margin-left: -16px;
    margin-right: -16px;

    ol,
    ul {
        padding-left: 12px;
        padding-right: 0;
        list-style: none;
        border-left: 1px solid fade(@tidy-rich-black, 10%);
    }

    li {
        position: relative;
        padding-left: 8px;



        &.openable {

            > ol,
            > ul {
                display: none;
            }

            &.open {

                > ol,
                > ul {
                    display: block;
                }

                > .item > .switch {
                    transform: rotate(90deg);
                }
            }

        }

    }

    .switch {
        position: absolute;
        left: -6px;
        top: 0;
        height: @tree-line-height;
        width: @tree-line-height;
        padding: 6px;
        text-align: center;
        transition: transform .2s;
        opacity: .6;
        cursor: pointer;

        .switch-icon::before {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid @tidy-rich-black;
            border-right: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform .2s;
            content: '';
        }

        &:hover {
            opacity: 1;
        }

    }

    .item {
        padding: 0 16px;

        //&:hover {
        //    background-color: lighten(@tidy-light-gray, 10%);
        //}
        //
        //&:active {
        //    background-color: @tidy-light-gray;
        //}

        .icon {
            width: 16px;
            transition: width .2s;

            &.folder {
                color: @tidy-dark-gray;
            }

            &.code {
                color: darkgreen;
            }
            &.plaintext {
                color: darkblue;
            }
            &.image {
                color: darkred;
            }
            &.style {
                color: darkorange;
            }
            &.amp {
                font-size: 24px;
                color: blue;
                display: inline-block;
                padding-left: 1px;
            }

        }

        .item-text {
            line-height: @tree-line-height;
            height: @tree-line-height;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                color: @tidy-dark-gray;

                .icon {
                    width: 17px;
                }

            }

            &.editable {

                &::after {
                    position: absolute;
                    top: 0;
                    right: 5px;
                    font-family: 'FontAwesome';
                    content: '\f040';
                    opacity: .2;
                }

            }

        }

        .item-btn {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            color: white;
        }

        &.unpublished .icon {
            color: @tidy-unpublished;
        }

        &.modified .icon {
            color: @tidy-unsaved;
        }

        &.linked .item-text::after {
            position: absolute;
            top: 0;
            right: 10px;
            font-family: 'FontAwesome';
            content: '\f0c1';
            opacity: .5;
        }

        &.disabled {
            .item-text {
                cursor: default;
                color: @tidy-gray;

                &:hover {
                    .icon {
                        width: 16px;
                    }
                }

            }

        }

    }

    //.openable > .item {
    //    &:active {
    //        background-color: transparent;
    //        color: @tidy-rich-black;
    //    }
    //}

    .active {
        @item-active-color: white;
        color: @item-active-color;
        background-color: @tidy-brand;

        &.item {

            .item-text:hover {
                color: @item-active-color;
            }

            .icon {
                color: @item-active-color;
            }

        }

        &.modified {
            color: @item-active-color;
            &.unpublished {
                color: @item-active-color;
            }
        }
        &.unpublished {
            color: @item-active-color;
        }

        //&.item {
        //
        //    &:active {
        //        background-color: @tidy-brand;
        //        color: white;
        //    }
        //
        //    .item-text {
        //
        //        &:active {
        //            background-color: @tidy-brand;
        //            color: white;
        //        }
        //
        //    }
        //
        //}

        .switch-icon::before {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid white;
        }

        .item-btn {
            display: block;
        }

        &::before {
            position: absolute;
            left: -248px;
            width: 256px;
            height: @tree-line-height;
            top: 0;
            background-color: @tidy-brand;
            content: '';
        }

        &::after {
            position: absolute;
            right: -8px;
            width: 8px;
            height: @tree-line-height;
            top: 0;
            background-color: @tidy-brand;
            content: '';
        }

    }

    &.with-root {
        overflow-x: hidden;

        > li {
            margin-left: -16px;

            > ol,
            > ul {
                border-left: 0;
            }

        }

        .item-text {
            padding-right: 16px;
        }

    }

    &.dom {
        margin-top: 11px;

        ol,
        ul {
            padding-left: 6px;
        }

        li {
            &.hover {
                border-radius: 4px;
                box-shadow: inset 0 0 0 1px @tidy-light-gray;
                background-color: @tidy-background-light-gray;
                color: @tidy-rich-black;

                &::before,
                &::after {
                    content: none;
                }

                &.active {
                    box-shadow: inset 0 0 0 2px @tidy-highlight;
                    background-color: transparent;
                }

            }
            &.moving {
                border-radius: 4px;
                box-shadow: inset 0 0 0 1px @tidy-light-gray;
                ol,
                ul {
                    &.dropzone {
                        min-height: 20px;
                    }
                }
            }
        }

        .item {
            &:hover {
                background-color: transparent;
            }

            &:active {
                background-color: transparent;
            }
        }

        .item-text {
            text-transform: uppercase;
            padding-left: 22px;
            padding-right: 22px;
            margin-left: -22px;
            margin-right: -22px;

            small {
                text-transform: none;
            }
        }

        .add-block {
            display: none;
            background-color: @tidy-highlight;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 22px;
            height: 22px;
            line-height: 22px;
            border-radius: 11px;
            text-align: center;
            color: white;
            font-weight: 700;
            z-index: 1;
            cursor: pointer;
            animation-name: scaleInAddBlock;
            animation-duration: .3s;
            animation-fill-mode: forwards;
            animation-timing-function: @tidy-ease-heavy-in;
        }

        .indicator {
            display: none;
            height: 8px;
            position: absolute;
            border-radius: 4px;
            background-color: @tidy-highlight;
            animation-name: indicatorPulse;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        .active {
            box-shadow: inset 0 0 0 2px @tidy-highlight;
            border-radius: 5px;
            background-color: transparent;
            color: @tidy-rich-black;
            transition: margin .2s;

            .switch-icon::before {
                border-left: 5px solid @tidy-rich-black;
            }

            &::before,
            &::after {
                content: none;
            }

            > .item > .add-block {
                display: block;

                &.before {
                    top: -11px;
                }

                &.after {
                    bottom: -11px;
                }

                &.inside {
                    top: 5px;
                    right: 0;
                    left: auto;
                    //top: 50%;
                    //transform: translateY(-50%) translateX(100%);
                }

            }

            &.add-before {
                margin-top: 12px;

                > .item > .add-block.before {
                    display: none;
                }

                > .item > .indicator {
                    display: block;
                    top: -12px;
                    left: 0;
                    width: 100%;
                }

            }

            &.add-after {
                margin-bottom: 12px;

                > .item > .add-block.after {
                    display: none;
                }

                > .item > .indicator {
                    display: block;
                    bottom: -12px;
                    left: 0;
                    width: 100%;
                }

            }

            &.add-inside {

                > .item > .add-block.inside {
                    display: none;
                }

                > .item > .indicator {
                    display: block;
                    top: 13px;
                    right: 10px;
                    width: 35%;
                }

            }

            > .item > .item-text .item-btn {
                display: block;
                color: @tidy-rich-black;
            }

        }

    }

}
