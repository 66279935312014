@import (reference) '../../../node_modules/bootstrap/less/variables';

// === COLOR PALETTE ===

@tidy-brand: #360bd2;
@tidy-nav-background: #3e3e3e;
@tidy-nav-icon: #dddddd;
@tidy-rich-black: #1a1818;
@tidy-dark-gray: #4f4f4f;
@tidy-gray: #868686;
@tidy-light-gray: #d9d9d9;
@tidy-background-light-gray: #f6f6f6;
@tidy-highlight: #2aeaf3;
@tidy-light-green: #09f684;
@tidy-lighter-gray: #f4f4f4;
@tidy-include: #e123e7;
@tidy-shared: @tidy-include;
@tidy-shared-dark: #b91fbf;
@tidy-shared-light: #ffc3f7;
@tidy-version-green: #25d233;
@tidy-border-color: @tidy-light-gray;
@tidy-unsaved: @tidy-highlight;
@tidy-deleted: #c7001b;
@tidy-unpublished: #e6c200;
@tidy-link-color: @tidy-rich-black;
@tidy-link-color-hover: @tidy-dark-gray;
@tidy-link-color-active: @tidy-rich-black;
@tidy-center-background-color: #f5f5f5;

@tidy-analytics-info: #0a8ad1;
@tidy-analytics-success: #09c07b;
@tidy-analytics-warning: #cdb411;
@tidy-analytics-danger: #df5d7b;

@tidy-color-state-error: @state-danger-text;
@tidy-color-state-valid: @tidy-version-green;

// ===== TIDY FLASH ======

@tidy-flash-color-green: #1ae79a;
@tidy-flash-color-blue: #4165bc;
@tidy-flash-color-purple: #7d2bd8;
@tidy-flash-color-violet: #e123e7;

@tidy-flash-mono1: #c8c8c8;
@tidy-flash-mono2: #535353;
@tidy-flash-mono3: #3a3a3a;
@tidy-flash-mono4: #a7a7a7;

// === TYPOGRPAHY ===

@tidy-font-size: 14px;
@tidy-font-size-sm: 12px;
@tidy-font-size-lg: 16px;
@tidy-font-size-xl: 22px;

// === TIDY ===

@tidy-btn-height: 40px;
@tidy-btn-height-lg: 48px;
@tidy-btn-height-sm: 25px;
@tidy-box-shadow: 0 0 10px 0 fade(@tidy-rich-black, 15%);
@tidy-box-shadow-lg: 0 0 20px 0 fade(@tidy-rich-black, 20%);
@tidy-box-shadow-sm: 0 0 5px 0 fade(@tidy-rich-black, 15%);
@tidy-sidepanel-width: 300px;
@tidy-border-radius: 3px;
@tidy-border-radius-lg: 6px;
@tidy-border-radius-sm: 2px;

// === ANIMATION EASINGS ===
@tidy-ease-in-out: cubic-bezier(0.8, 0, 0.3, 1);
@tidy-ease-heavy-in: cubic-bezier(0.9, 0, 0.1, 1);

@amp-blue: #0379c4;
