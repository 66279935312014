@import '_variables';



.layout-add-block {
  flex-shrink: 0;
  flex-basis: 500px;
  transition: flex-basis .2s;
  order: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 48px rgba(0, 0, 0, .2);
  z-index: 1;
  margin-left: -1px;
  background-color: @tidy-background-light-gray;
  opacity: 0.5;
  animation-name: fadeInFromLeft;
  animation-duration: .35s;
  animation-fill-mode: forwards;

  &.closed {
    flex-basis: 0;
    border-width: 0;
  }

  .top {
    flex-shrink: 0;
    padding: 8px;
    //border-right: 1px solid @tidy-light-gray;
    //background-color: @tidy-background-light-gray;
    .search {
      width: 90%;
      float: left;
    }
    .btn-link {
      float: right;
    }
  }

  .main {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    border-top: 1px solid @tidy-light-gray;

    h2 {
      margin: 16px;
    }

    h4 {
      margin: 32px 16px 16px 16px;
      padding-bottom: 4px;
      border-bottom: 1px solid @tidy-light-gray;
    }

    .blocks {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;
      //background-color: @tidy-background-light-gray;

      .block-inner-container {
        width: 335px; //quick unresponsive scrollbar bugfix by fredi, needs to be fixed if becomes responsive

        .block-list {
          display: flex;
          flex-wrap: wrap;
          padding-left: 8px;
          padding-right: 8px;
          list-style: none;
          border-bottom: 1px solid fade(white, 10%);
          margin: 0;

          .block {
            width: 100%;
            cursor: pointer;
            padding: 0 8px 21px 8px;
            opacity: .75;
            position: relative;

            &:hover {
              opacity: 1;

              .title {
                opacity: 1;
                transform: translateY(0px);
              }

            }

            img {
              max-height: 200px;
              margin: auto;
            }


            .text-block {
              background-color: white;
              //border: 1px solid @tidy-light-gray;
              box-shadow: 0 3px 16px rgba(0, 0, 0, .1);
              border-radius: 6px;
              line-height: 1em;
              padding: 24px 16px;
              font-weight: 300;

              &.text-lg {
                font-size: 24px;
              }
            }

            .title {
              background: @tidy-rich-black;
              border-radius: 3px;
              line-height: 16px;
              position: absolute;
              padding: 0.4em 1em;
              color: white;
              text-align: center;
              opacity: 0;
              top: -8px;
              left: 4px;
              transform: translateY(-3px);
              transition: opacity .15s linear, transform .15s ease-in;
              font-size: @tidy-font-size-sm;
            }

            .grid {
              background-color: rgba(86, 61, 124, .15);
              border: 1px solid rgba(86, 61, 124, .2);
              padding: 10px;
              height: 42px;
              white-space: nowrap;

              &.nested {
                font-size: 11px;
                padding: 2px;
                height: auto;
                width: 100%;
                float: left;
              }

            }

          }

          &.half .block {
            width: 50%;
          }

          &.third .block {
            width: 33.333333%;
          }

          .border {
            border: 1px solid @tidy-light-gray;
          }

        }

      }

    }

    .nav {
      flex-shrink: 0;
      //background-color: @tidy-rich-black;
      //color: white;
      padding-top: 8px;
      overflow-y: auto;
      background-color: darken(@tidy-background-light-gray, 3%);

      ol {
        padding: 0;
        list-style-type: none;

        li {
          padding: 8px 16px;
          cursor: pointer;
          color: fadeout(@tidy-rich-black, 30%);

          &:hover {
            //background-color: lighten(@tidy-rich-black, 5%);
            background-color: @tidy-background-light-gray;
            color: fadeout(@tidy-brand, 20%);
          }

          &.active {
            //background-color: lighten(@tidy-rich-black, 10%);
            background-color: @tidy-background-light-gray;
            color: @tidy-brand;
          }

          &:last-child {
            margin-top: 20px;
            //color: lighten(@tidy-brand,40%);
            color: darken(@tidy-highlight, 10%);
          }

        }
      }


    }

  }

}
