@import "_variables";

.flash-logo {
  .st0 {
    fill: @tidy-flash-color-green;
  }
  .st1 {
    fill: @tidy-flash-color-blue;
  }
  .st2 {
    fill: @tidy-flash-color-purple;
  }
  .st3 {
    fill: @tidy-flash-color-violet;
  }
}
