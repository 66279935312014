@import '_variables';

.demo-account {

  &-notification {

    position: absolute;
    bottom: 0;
    left: 20%;
    right: 20%;
    background-color: fade(@tidy-flash-color-purple, 75%);
    z-index: 2147483647;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: @tidy-box-shadow;

    height: 50px;
    padding: 5px 5px 0 10px;

  }

}
