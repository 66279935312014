@import '_variables';

@auth-color-facebook: #4165bc;
@auth-color-google: #cf3826;
@auth-color-demo: @tidy-flash-color-purple;
@auth-color-tidy-login: @tidy-brand;
@auth-color-tidy-account: darken(@tidy-flash-color-violet, 10%);

.auth-wrapper {
  .btn {
    color: white;
    position: relative;

    &[submitting] {
      color: transparent;
    }
    &.demo-account-button,
    &.facebook,
    &.google {
      cursor: pointer;
      &:before {
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 16px;
        top: 14px;
      }
    }
    &.facebook {
      background-color: @auth-color-facebook;
      border-color: @auth-color-facebook;
      &:hover {
        background-color: darken(@auth-color-facebook, 10%);
      }
      // Render FB logo with Fontawesome
      &:before {
        content: '\f230';
      }
    }
    &.google {
      background-color: @auth-color-google;
      border-color: @auth-color-google;
      &:hover {
        background-color: darken(@auth-color-google, 10%);
      }
      // Render Google logo with Fontawesome
      &:before {
        content: '\f1a0';
      }
    }
    &.demo-account-button {
      background-color: @auth-color-demo;
      border-color: @auth-color-demo;
      &:hover {
        background-color: darken(@auth-color-demo, 10%);
      }
      &:before {
        content: '\f007';
      }
    }
    &.login-accordion-button {
      color: @text-color;
      &:hover {
        color: @tidy-brand;
      }
    }
    &.tidy-login {
      background-color: @auth-color-tidy-login;
      border-color: @auth-color-tidy-login;
      &:hover {
        background-color: darken(@auth-color-tidy-login, 10%);
      }
    }
    &.tidy-create-account {
      background-color: @auth-color-tidy-account;
      border-color: @auth-color-tidy-account;
      &:hover {
        background-color: darken(@auth-color-tidy-account, 10%);
      }
    }
  }
  .nav-tabs {
    margin-top: -22px;
    margin-bottom: 30px;
    margin-left: -48px;
    margin-right: -48px;
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    & > li {
      float: none;
      display: inline-block;
      margin: 0 3px;
      border-radius: 0;
      & > a {
        border-radius: 0;
      }
      &:hover,
      &:active {
        background: transparent;
      }
      & > a {
        border: 0;
        color: fade(@tidy-brand, 50%);
        border-bottom: 2px solid fade(@tidy-brand, 20%);
        text-transform: uppercase;
        background: transparent;
        &:focus,
        &:hover {
          background: transparent;
          border-bottom: 2px solid fade(@tidy-brand, 60%);
          color: fade(@tidy-brand, 80%);
        }
      }
      &.active > a,
      &.active > a:focus,
      &.active > a:hover {
        border: 0;
        color: @tidy-brand;
        border-bottom: 2px solid @tidy-brand;
        text-transform: uppercase;
      }
    }
  }

  .auth-link {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 16px;
      font-size: 14px;
      padding-bottom: 16px;
      margin-bottom: -16px;
  }
}

.create-modal {
  .modal-header {
    padding: 16px 32px 0;
  }
}

.login-or-divider {
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 14px;
  margin: 20px 0;
  span {
    text-transform: uppercase;
    background: white;
    padding: 6px 10px;
    position: relative;
  }
  &:before {
    content: '';
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    position: absolute;
    height: 1px;
    width: 100%;
    top: 10px;
    left: 0;
  }
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //background-color: @tidy-brand;
  //background-image: radial-gradient(50% 100%, @tidy-brand 0%, darken(@tidy-brand, 10%) 100%);
  background-color: white;
  background-image: radial-gradient(50% 100%, white 0%, #f1f1f1 100%);

  a {
    cursor: pointer;
  }

  .auth-box {
    flex-basis: 400px;
    padding: 32px;
    border-radius: @border-radius-base;
    background-color: white;
    margin-top: -5vh; // visual centering and clearing way for new site creation -link
    box-shadow: 0 17px 74px 0 fade(@tidy-rich-black, 15%);
    opacity: 0;
    animation-name: scaleInLoginBox;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    animation-timing-function: @tidy-ease-heavy-in;

    .logo {
      display: block;
      text-align: center;
      padding: 0;
      margin: 16px 0 32px;
      img {
        height: 45px;
      }
    }

    //.btn-row {
    //  padding-top: 16px;
    //}

    .auth-link-group {
      display: flex;
      justify-content: space-between;

      a {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: -16px;
      }

    }



    .create-new-site {
      display: block;
      width: 100%;
      opacity: 0.4;
      position: absolute;
      text-align: center;
      bottom: -60px;
      left: 0;
      &:hover,
      &:active {
        opacity: 1;
      }
    }

  }

}
