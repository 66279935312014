@import '../../../node_modules/bootstrap/less/variables';
@import (reference) '../../../node_modules/font-awesome/less/variables';
@import '_variables';

// == Dropdown

@dropdown-bg: @tidy-rich-black;
@dropdown-border: transparent;
@dropdown-fallback-border: transparent;
@dropdown-divider-bg: @tidy-dark-gray;
@dropdown-link-color: white;
@dropdown-link-hover-color: fade(#ffffff, 90%);
@dropdown-link-hover-bg: @tidy-rich-black;

// == Button

@btn-font-weight: 400;
//@btn-default-border: @tidy-rich-black;
//@btn-default-bg: white;
//@btn-default-border: @tidy-dark-gray;
//@btn-default-active: white;
@btn-default-border: transparent;
@btn-default-bg: lighten(@tidy-brand, 52%);
@btn-default-color: @tidy-brand;
@btn-default-hover: darken(@btn-default-bg, 15%);
@btn-default-active: darken(@btn-default-bg, 30%);

@btn-primary-color: white;
@btn-primary-bg: @tidy-brand;
@btn-primary-border: @tidy-brand;
@btn-primary-disabled: desaturate(@btn-primary-bg, 85%);
@btn-primary-hover: darken(@btn-primary-bg, 15%);
@btn-primary-active: darken(@btn-primary-bg, 30%);
@btn-link-disabled-color: @tidy-light-gray;

// == Form

@input-height-base: 40px;
@input-bg: fade(@tidy-gray, 12%);
@input-color: @tidy-rich-black;
@input-border: @input-bg;
@input-border-focus: @tidy-light-gray;
@input-color-placeholder: @tidy-gray;
@form-group-margin-bottom: 0;
@input-group-addon-bg: @tidy-rich-black;

@border-radius-base: @tidy-border-radius;
@border-radius-large: @tidy-border-radius-lg;
@border-radius-small: @tidy-border-radius-sm;

// == Modal
@modal-inner-padding: 16px;
@modal-title-padding: 16px;
@modal-backdrop-bg: black;
@modal-backdrop-opacity: .85;
@modal-header-border-color: @tidy-light-gray;

@modal-lg: 900px;
@modal-md: 600px;
@modal-sm: 300px;

// == FONTS
@icon-font-path: '../../common/fonts/';

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    padding: 2px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 3px @tidy-light-gray;
    &:hover {
        box-shadow: inset 0 0 0 3px darken(@tidy-light-gray, 10%);
    }
}

body {
    overflow: hidden;
}

.btn {
  height: @tidy-btn-height;
  border-radius: 3px;
  transition: background-color .2s ease;

  a& {
        padding-top: 9px;
  }

  &.btn-circle {
        padding: 1px 0 0 0;
        width: @tidy-btn-height;
        border-radius: 50%;
        line-height: 0;

        //background: @tidy-brand;
        border: none;
        color: white;
        &:hover,
        &:active {
            //background: darken(@tidy-brand, 20%);
            color: white;
        }
  }

  &.btn-sm {
        font-size: @tidy-font-size-sm;
        height: @tidy-btn-height-sm;
        line-height: @tidy-font-size-sm;

        &.btn-circle {
            width: @tidy-btn-height-sm;
            line-height: 0;
        }
  }

  &.btn-lg {
    font-size: @tidy-font-size-lg;
    height: @tidy-btn-height-lg;
    line-height: @tidy-font-size-lg;
    padding-left: 32px;
    padding-right: 32px;

    &.btn-circle {
      width: @tidy-btn-height-lg;
      padding-left: 0;
      padding-right: 0;
      line-height: 0;
    }
  }

  &.btn-icon {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 6px 16px;
    &.btn-multiline {
      display: block;
      height: 60px;

      .icon {
        display: block;
        margin-bottom: 4px;
      }
    }
  }

  &.btn-link {
    color: @tidy-rich-black;
    text-decoration: none;

    &:focus {
      color: @tidy-rich-black;
      text-decoration: none;
    }

    &:hover {
      color: @tidy-link-color-hover;
      text-decoration: none;
    }
  }

  &.btn-primary {
    &:hover,
    &:focus {
      background-color: @btn-primary-hover;
      border-color: @btn-primary-hover;
    }
    &:active {
      background-color: @btn-primary-active;
      border-color: @btn-primary-active;
      box-shadow: none;
    }
  }

  &.btn-outline {
    color: @tidy-brand;
    background-color: transparent;
    border-color: @btn-primary-border;
    &:hover,
    &:focus {
      color: white;
      background-color: @btn-primary-hover;
      border-color: @btn-primary-hover;
    }

    &:active {
      color: white;
      background-color: @btn-primary-active;
      border-color: @btn-primary-active;
      box-shadow: none;
    }
  }

  &.btn-select-date {
    color: @text-color;
    background-color: transparent;
    border-color: @gray-light;

    &:hover,
    &:focus {
      color: @text-color;
      border-color: @gray;
    }

    &:active {
      border-color: @gray;
    }
  }

  &:disabled {
    opacity: 0.25;
    &.btn-primary {
      background-color: @btn-primary-disabled;
      border-color: @btn-primary-disabled;

      &.btn-outline {
        color: @btn-primary-disabled;
        border-color: @btn-primary-disabled;
        background-color: transparent;
      }
    }
    &.btn-default {
      background-color: white;
    }
  }

  &:focus,
  &:focus:active,
  &.btn-link:focus,
  &.btn-link:focus:active {
    outline: 0;
    box-shadow: none;
  }

  &[submitting] {
    position: relative;
    color: transparent;
    cursor: wait;
    pointer-events: none;
    &:before {
      content: @fa-var-circle-o-notch;
      color: white;
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -6px;
      font-family: 'FontAwesome', sans-serif;
      animation: fa-spin 4s linear 0s infinite;
    }
  }

}

.btn-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: baseline;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 16px;

  .btn {
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;

    &.btn-circle {
      flex-grow: 0;
    }

  }

  &.space-between {
    justify-content: space-between;

    .btn {
      flex-grow: 0;
    }

  }

}

.dropdown-menu {

  box-shadow: 0 5px 28px rgba(0, 0, 0, .2);

  opacity: 0.5;
  animation-name: smallFadeInFromBottom;
  animation-duration: .15s;
  animation-fill-mode: forwards;

  > li > a {
    padding: 10px 18px;
    border-bottom: 1px solid darken(@tidy-dark-gray, 10%);
  }

  li:last-child a {
    border-bottom: 0;
  }

  .dropdown-link-icon {
    display: flex;

    align-items: baseline;
    padding: 10px 12px;

    .icon {
      padding-left: 8px;
      padding-right: 12px;
      font-size: 16px;
      width: 38px;
    }

  }

  &::before {
    position: absolute;
    left: 12px;
    top: -8px;
    border-bottom: 9px solid @tidy-rich-black;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    content: '';
  }

  &.dropdown-menu-left {
    &::before {
      left: 5px;
      right: auto;
    }
  }

  &.dropdown-menu-right {
    animation-name: smallFadeInFromTop;
    .dropdown-link-icon {
      justify-content: space-between;
      .icon {
        padding-right: 8px;
        width: auto;
      }
    }
    &::before {
      left: auto;
      right: 5px;
    }

  }

  &.dropdown-menu-top {

    &::before {
      top: auto;
      bottom: -8px;
      border-bottom: 0;
      border-top: 9px solid @tidy-rich-black;
    }

  }

}

.input-unit {
  display: flex;
  align-content: stretch;

  input {
    flex-grow: 1;
    border: 0;
    background-color: transparent;
  }

  select {
    border: 0;
    background-color: transparent;
    font-size: @tidy-font-size-sm;
  }

}

.form-control {
  border: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, .1);
  &.input-display {
    background: none;
    border: 0;
    box-shadow: none;
    pointer-events: none;
  }
  &.input-lg {
    border-radius: 3px;
    font-size: 16px;
  }
}

.form-group {
    padding: 0 0 16px;
    //margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
    label {
        font-size: @tidy-font-size-sm;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: 0.5em;
        &:first-child {
          margin-top: 0;
        }
        &.no-transform {
          text-transform: none;
        }
    }

  .checkbox {
    height: auto;
    label {
      display: block;
      font-size: @tidy-font-size;
      text-transform: none;
    }
  }

  &.has-error,
  .has-error {

    label {
      font-weight: 600;
      color: @tidy-color-state-error;
    }

  }

  .help-block {
    font-size: @tidy-font-size-sm;
    margin-bottom: 0;
  }

}

.input-group {

  .input-group-addon {
    border-color: @tidy-rich-black;
    color: white;

    &.field-status {
      min-width: 40px;

      background-color: #dedddd;
      border-color: @input-bg;

      .field-status-before() {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }

      &-error {
        background-color: @tidy-color-state-error;
        border-color: @tidy-color-state-error;
        &:before {
          .field-status-before();
          content: @fa-var-exclamation;
        }
      }
      &-valid {
        background-color: @tidy-color-state-valid;
        border-color: @tidy-color-state-valid;
        &:before {
          .field-status-before();
          content: @fa-var-check;
        }
      }
      &-loading {
        background-color: transparent;
        border-color: transparent;
        &:before {
          .field-status-before();
          content: @fa-var-spinner;
          color: @tidy-brand;
          animation: fa-spin 2s infinite linear;
        }
      }
    }

  }

}

.select-option {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  opacity: .5;
  border-bottom: 1px solid @tidy-border-color;
  * {
    cursor: pointer;
  }
  .icon {
    font-size: 2em;
    margin-top: 0.2em;
    padding-right: 10px;
  }
  label {
    font-size: 16px;
    font-weight: 400;
    display: block;
  }
  &.active {
    opacity: 1;
  }
  &.success {
    background-color: lighten(@brand-success, 20%);
  }
  &.warning {
    background-color: lighten(@brand-warning, 20%);
  }
  &.danger {
    background-color: lighten(@brand-danger, 20%);
  }
  &:hover {
    opacity: 1;
  }
  &:last-child {
    border-bottom: 0;
  }
}

.panel-group {

  .panel {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;

    + .panel {
      margin-top: 0;
      padding-bottom: 0;
    }

    .panel-title {
      font-size: 12px;
      font-weight: 400;

      a {
        padding: 12px;
        display: block;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid black;
          border-bottom: 0;
          position: absolute;
          top: 16px;
          left: -2px;
          transition: transform .2s;
        }

        &.collapsed::before {
          transform: rotate(-90deg);
        }

      }

      &.with-thumb {
        position: relative;
        padding-left: 70px;

        .thumb {
          position: absolute;
          top: -2px;
          left: -67px;
          bottom: -2px;
          width: 55px;
          background-size: cover;
          background-position: center;
        }
      }

    }

    .panel-body {
      margin-top: 0;
      margin-bottom: 16px;
      padding: 8px 0;

      h2,
      h3 {
        margin-top: 8px;
        margin-bottom: 0;
      }

      //div {
      //    padding: 0 0 4px;
      //    &:first-child {
      //        padding-top: 8px;
      //    }
      //    &:last-child {
      //        padding-bottom: 8px;
      //    }
      //}
    }
  }

  &.sortable {

    .thumb {
      cursor: ns-resize;
    }

  }

}

.modal-backdrop.fade {
  transition: opacity .1s linear;
}

.modal {
  &.fade .modal-dialog {
    opacity: 0;
    transition: all .15s ease;
    transform: translate(0, 0) scale(0.95);
  }
  &.in .modal-dialog {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  .modal-content {
    box-shadow: none;
    border: 0;
    border-radius: 3px;
    padding: 16px 16px;
  }

  .modal-flex-container {
    display: flex;
    height: 800px;
    max-height: 90%;

    .modal-flex-side {
      flex-shrink: 0;
      background-color: @tidy-background-light-gray;
      padding: 16px;
      overflow-y: auto;

      &.left {
        order: 1;
        border-right: 1px solid @tidy-border-color;
        border-top-left-radius: @tidy-border-radius;
        border-bottom-left-radius: @tidy-border-radius;
      }

      &.right {
        order: 3;
        border-left: 1px solid @tidy-border-color;
        border-top-right-radius: @tidy-border-radius;
        border-bottom-left-radius: @tidy-border-radius;
      }

    }

    .modal-flex-center {
      order: 2;
      display: flex;
      flex-direction: column;
      width: 100%;

      .modal-body {
        flex-grow: 1;
        position: relative;
      }

    }

  }
  .modal-body {
    padding-left: 32px;
    padding-right: 32px;
  }

  .modal-header {
    padding: 28px 32px 0;
  }

  .modal-footer {
    padding: 24px 48px 16px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .modal-title {
    padding: 0 !important; // lesshint importantRule: false
  }

  //.modal-header,
  //.modal-footer {
  //    display: flex;
  //    justify-content: space-between;
  //    align-items: center;
  //    padding: 24px 32px;
  //
  //    .btn-link {
  //        margin-left: -16px;
  //        margin-right: -16px;
  //    }
  //
  //    &::after,
  //    &::before {
  //        content: none;
  //    }
  //}
  //
  //.modal-header {
  //    padding-top: 32px;
  //    border-bottom: 0;
  //}

  //.modal-body {
  //    padding-left: 32px;
  //    padding-right: 32px;
  //}

  .modal-header {
    //padding-top: 32px;
    border-bottom: 0;
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: auto;
    }
  }

  .modal-xl {
    width: 90%;
    max-width: 1280px;
  }

}

a.brand-primary {
  color: @tidy-brand;
}

.progress {
  box-shadow: none;
  &-bar {
    box-shadow: none;
  }
}

.table {
  &.vertical-middle {
    * {
      vertical-align: middle;
    }
  }
}
