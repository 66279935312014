// lesshint spaceAroundComma: false

@fa-font-path: '/_dist/common/fonts/';

/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Italic'), local('Roboto-Italic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black'), local('Roboto-Black'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/_dist/common/fonts/roboto/roboto-v15-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: "tidy-icons";
  src: url("/_dist/common/fonts/tidy-icons.eot");
  src: url("/_dist/common/fonts/tidy-icons.eot?#iefix") format("embedded-opentype"),
    url("/_dist/common/fonts/tidy-icons.woff") format("woff"),
    url("/_dist/common/fonts/tidy-icons.ttf") format("truetype"),
    url("/_dist/common/fonts/tidy-icons.svg#tidy-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "tidy-icons" !important; // lesshint importantRule: false
  content: attr(data-icon);
  font-style: normal !important; // lesshint importantRule: false
  font-weight: normal !important; // lesshint importantRule: false
  font-variant: normal !important; // lesshint importantRule: false
  text-transform: none !important; // lesshint importantRule: false
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="tidyicon-"]:before,
[class*=" tidyicon-"]:before {
  font-family: "tidy-icons" !important; // lesshint importantRule: false
  font-style: normal !important; // lesshint importantRule: false
  font-weight: normal !important; // lesshint importantRule: false
  font-variant: normal !important; // lesshint importantRule: false
  text-transform: none !important; // lesshint importantRule: false
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tidyicon-edit:before {
  content: "\62";
}
.tidyicon-face-neutral:before {
  content: "\63";
}
.tidyicon-face-sad:before {
  content: "\64";
}
.tidyicon-face-smiling:before {
  content: "\65";
}
.tidyicon-face-happy:before {
  content: "\66";
}
.tidyicon-face-happy-bling:before {
  content: "\67";
}
.tidyicon-files:before {
  content: "\68";
}
.tidyicon-dashboard:before {
  content: "\61";
}
.tidyicon-templates:before {
  content: "\69";
}
.tidyicon-users:before {
  content: "\6a";
}
.tidyicon-home:before {
  content: "\6b";
}
.tidyicon-settings:before {
  content: "\6c";
}
.tidyicon-sliders:before {
  content: "\6d";
}
.tidyicon-styles:before {
  content: "\6e";
}
.tidyicon-target:before {
  content: "\6f";
}
.tidyicon-arrow-up:before {
  content: "\70";
}
.tidyicon-arrow-down:before {
  content: "\71";
}
.tidyicon-arrow-back:before {
  content: "\72";
}
