@import '_variables';

.layout-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100vh;

    .layout-main {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .layout-item {
        position: relative;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;

        @import '_layout-nav';
        @import '_layout-center';
        @import '_layout-side';

    }

}
