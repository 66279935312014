@import '_variables';

&.layout-side {
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: @tidy-sidepanel-width;
    padding-top: 16px;
    transition: flex-basis .2s;

    &.left {
        order: 1;
        border-right: 1px solid @tidy-border-color;

        .template-used {
            padding: 16px;
        }

    }

    &.right {
        order: 3;
        border-left: 1px solid @tidy-border-color;
    }

    > div {
        min-width: @tidy-sidepanel-width - 32px;

        h2,
        h3,
        h4 {
            padding: 5px 16px;
            flex-shrink: 0;
        }
        h2 {
            font-size: 18px;
            padding: 11px 16px;
        }
    }

    hr {
        border-color: @tidy-border-color;
        margin: 0 0 15px 0;
    }

    h2,
    h3,
    h4 {
        padding: 8px 16px;
        flex-shrink: 0;
    }

    .form-group {
        padding-left: 16px;
        padding-right: 16px;
    }

    .btn-row {
        padding-left: 16px;
        padding-right: 16px;
    }

    .side-content {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 16px;
        //margin-top: 16px;
    }

    .side-inline-dialog {
        animation-name: smallFadeInFromRight;
        animation-duration: .3s;
        animation-fill-mode: forwards;
    }

    .side-info {
        padding: 16px;
        border-bottom: 1px solid @tidy-border-color;
        background: @tidy-background-light-gray;
    }

    .side-link-group {
        margin: 20px 16px 16px;
        border-radius: 3px;
        border: 1px solid @tidy-border-color;

        .btn-link {
            border-radius: 0;
            width: 100%;
            height: auto;
            padding: 0;
            border-bottom: 1px solid @tidy-border-color;

            &:last-child {
                border-bottom: 0;
            }
            > span {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding: 14px 16px;
                width: 100%;
                height: auto;
            }
        }

        .side-item {
            border-bottom: 1px solid @tidy-border-color;

            .checkbox {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding: 16px;
                margin: 0;

                label {
                    flex-grow: 1;
                    justify-content: space-between;
                    align-items: baseline;
                    display: flex;
                    input {
                        position: static;
                    }

                    span {
                        flex-grow: 1;
                        text-align: right;
                    }
                }
            }
        }

    }

    .side-link {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: baseline;
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: -16px;
        padding: 16px;

        &.btn {
            box-sizing: content-box;
            width: 100%;
            height: auto;
        }
    }

    .side-title-btn {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 16px;

        h4 {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }

        .btn {
            margin: -5px 0 0;
        }

        .btn-link {
            padding: 0;
            height: @tidy-btn-height-sm;
            width: @tidy-btn-height-sm;
            text-align: center;

        }

        .dropdown {
            border: 0;
            margin-bottom: 0;
        }

        .dropdown.open {
            .dropdown-toggle {
                background: @tidy-rich-black;
                color: white;
            }
        }
    }

    .side-stretch {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 128px;
        position: relative;

        > .side-content {
            flex-grow: 1;
            flex-shrink: 1;
            overflow-y: auto;
            overflow-x: hidden;
            padding-bottom: 24px;
            margin-bottom: 0;
        }

        &:after {
            content: '';
            background: linear-gradient(to bottom, fade(white, 0%) 0%, fade(white, 75%) 50%, white 100%);
            position: absolute;
            width: 100%;
            height: 32px;
            bottom: 0;
            pointer-events: none;
        }

    }

    .logo {
        height: 35px;
        &.whitelabel {
            max-width: 100%;
            max-height: 60px;
            height: auto;
        }
    }

    .panel-group:not(.panel-in-modal) {
        margin-bottom: 0;
        border-top: 1px solid @tidy-border-color;

        .group-link {
            margin-bottom: -16px;
        }

        .panel {

            .panel-body {
                padding: 16px 0 8px;
                margin-bottom: 0;
                box-shadow: inset 0 2px 20px rgba(0, 0, 0, .1);
                border-bottom: 1px solid @tidy-border-color;
            }

            .panel-title {
                border-bottom: 1px solid @tidy-border-color;
            }

            .panel-collapse {
                //border-bottom: 1px solid @tidy-border-color;
                background-color: lighten(@tidy-background-light-gray, 6%);
            }

        }

        h2 {
            border-top: 1px solid rgba(0, 0, 0, .08);
            padding-top: 16px;
            color: rgba(0, 0, 0, .5);
            font-size: 12px;
            text-transform: uppercase;
        }

    }

    &.closed {
        flex-basis: 0;
        border-width: 0;

        &.peek-close {
            //flex-basis: 48px;
            flex-basis: 0;
            border-width: 1px;

            .peek-hide {
                display: none;
            }

            .btn-row {
                display: block;
                margin-bottom: 8px;

                .btn {
                    display: block;
                    margin: 0 0 8px 0;

                    .icon {
                        margin-right: 64px;
                    }
                }
            }
        }

    }

    .subheading {
        margin-top: -16px;
        margin-left: 16px;
        margin-bottom: 24px;
        color: @tidy-gray;
    }

    &.home {

        flex-basis: 20%;
        min-width: 200px;
        &.site-info {
            min-width: 200px;
        }
        a {
            color: @tidy-brand;
            cursor: pointer;
        }

        .home-side-group {
            border-bottom: 1px solid @gray-lighter;
            padding: 2em 0;
            h4 {
                opacity: 0.5;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }

        .home-header {
            border-bottom: 1px solid @gray-lighter;
            padding-bottom: 2em;
            .logo {
                height: 48px;
                margin-top: -7px;
                margin-bottom: 22px;
            }
            .logo + h2 {
                margin-bottom: 0;
            }
        }
        .home-dropdown {
            .btn {
                text-align: left;
                padding-left: 16px;
                font-weight: 300;
                box-shadow: 0 3px 3px rgba(0,0,0,.06);
                .caret {
                  position: absolute;
                  right: 16px;
                  top: 22px;
                }
            }
            .compared-to {
                opacity: 0.5;
                float: right;
            }
            .dropdown-menu {
                width: 100%;
                a {
                    color: white;
                }
            }
        }
        .container {
            width: auto;
            min-width: none;
            padding: 8px 24px;
            margin: 0;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding: 0;
        }
    }

    .file-syntax-status {
        h2 {
            color: @brand-danger;
            margin-bottom: 0;
        }
        .alert {
            margin-bottom: 10px;
        }
    }

}
