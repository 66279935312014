@import '_variables';

// Color variables (appears count calculates by raw css)
@color0: #eeeeee; // Appears 8 times
@color1: #f0f0f0; // Appears 4 times

@onoff-height: 25px;
@onoff-height-sm: @onoff-height * .8;
@onoff-height-lg: @onoff-height * 1.2;

@onoff-default: @tidy-analytics-success;
@onoff-blue: @tidy-analytics-info;

.onoff {
  background-color: white;
  background-image: linear-gradient(to bottom, @color0, white 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: inline-block;
  height: @onoff-height;
  padding: 3px;
  position: relative;
  vertical-align: top;
  width: @onoff-height * 3;

  &-input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    &:checked~.onoff-label {
      background: @onoff-default;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
    &:checked~.onoff-handle {
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
      left: @onoff-height * 2;
    }
  }

  &.blue>.onoff-input {
    &:checked~.onoff-label {
      background: @onoff-blue;
    }
  }

  &-label {
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    display: block;
    font-size: @onoff-height / 2;
    height: inherit;
    position: relative;
    text-transform: uppercase;
    transition-property: opacity, background;
    transition: 0.15s ease-out;
    &:before {
      color: #aaaaaa;
      content: attr(data-off);
      line-height: 1;
      margin-top: -.5em;
      position: absolute;
      right: 11px;
      text-shadow: 0 1px rgba(255, 255, 255, 0.5);
      top: 50%;
      transition: inherit;
    }
    &:after {
      color: white;
      content: attr(data-on);
      left: 11px;
      line-height: 1;
      margin-top: -.5em;
      opacity: 0;
      position: absolute;
      text-shadow: 0 1px rgba(0, 0, 0, 0.2);
      top: 50%;
      transition: inherit;
    }
  }

  &-handle {
    background-image: linear-gradient(to bottom, white 40%, @color1);
    background: white;
    border-radius: @onoff-height;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    height: @onoff-height - 2px;
    left: 4px;
    position: absolute;
    top: 4px;
    transition: left 0.15s ease-out;
    width: @onoff-height - 2px;
    &:before {
      background-image: linear-gradient(to bottom, @color0, white);
      background: #f9f9f9;
      border-radius: 6px;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
      content: '';
      height: 12px;
      left: 50%;
      margin: -6px 0 0 -6px;
      position: absolute;
      top: 50%;
      width: 12px;
    }
  }

  &.onoff-sm {
    height: @onoff-height-sm;
    width: @onoff-height-sm * 3;
    .onoff-input {
      &:checked~.onoff-handle {
        left: @onoff-height-sm * 2;
      }
    }
    .onoff-label {
      font-size: @onoff-height-sm / 2;
    }
    .onoff-handle {
      border-radius: @onoff-height-sm;
      height: @onoff-height-sm - 2px;
      width: @onoff-height-sm - 2px;
    }
  }

  &.onoff-lg {
    height: @onoff-height-lg;
    width: @onoff-height-lg * 3;
    .onoff-input {
      &:checked~.onoff-handle {
        left: @onoff-height-lg * 2;
      }
    }
    .onoff-label {
      font-size: @onoff-height-lg / 2;
    }
    .onoff-handle {
      border-radius: @onoff-height-lg;
      height: @onoff-height-lg - 2px;
      width: @onoff-height-lg - 2px;
    }
  }

}
