@import '_variables';
@import '_animations';
@import '../../../node_modules/bootstrap/less/mixins';

@ai-bg-color: #360db2;
@ai-notification-color: red;
@ai-notification-size: 18px;

@ai-panel-width: 400px;
@ai-panel-bg: #ededed;

@ai-body-padding: 15px;
@ai-body-status-size: 60px;

@ai-color-critical: #c933cc;
@ai-color-upgrade: #b16c18;
@ai-color-improvement: #4485ff;
@ai-color-ignored: #adadad;
@ai-color-passed: #34d365;

@ai-status-marker-size: 15px;
@ai-message-box-shadow: 0 1px 5px rgba(0, 0, 0, .1), 0 4px 9px rgba(0, 0, 0, .08);
@ai-message-border-radius: 10px;

@ai-link-color: #360bd2;

.ai-container {

  .ai {

    &-button {

      position: absolute;
      cursor: pointer;
      bottom: 78px;
      right: 0;
      z-index: 99999;
      width: 55px;
      height: 50px;
      opacity: 1;
      background-color: @ai-bg-color;
      padding: 4px 9px 4px 4px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, .23);

      &-slide {
        &-transition {
          transition: all 1s ease;
        }
        &-enter,
        &-leave {
          right: -1 * 55px;
          opacity: 0;
        }
      }

      .ai-status {
        width: 100%;
        height: 100%;
      }

    }

    &-status {

      &-notification {
        position: absolute;
        right: 2px;
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: 10px;
        display: flex;
        z-index: 2;
        align-items: center;
        justify-content: center;
        background: @ai-notification-color;
        width: @ai-notification-size;
        height: @ai-notification-size;
        border-radius: @ai-notification-size;
      }

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        background-size: 42px;
        background-image: url('/_dist/common/img/aino/aino-default.svg');
        background-repeat: no-repeat;
        transition: background .2s linear;
        &-marker {
          display: inline-block;
          background: url('/_dist/common/img/aino/flash.svg') no-repeat center center;
          background-size: @ai-status-marker-size;
          width: @ai-status-marker-size;
          height: (@ai-status-marker-size + 5px);
          margin-top: -13px;
        }
        &.happy {
          background-image: url('/_dist/common/img/aino/aino-happy.svg');
        }
        &.thinking {
          background-image: url('/_dist/common/img/aino/aino-thinking.svg');
          .ai-status-image-marker {
            width: (@ai-status-marker-size + 5px);
            background-image: url('/_dist/common/img/aino/think.svg');
            animation: spin .7s ease 0s infinite;
          }
        }
      }

    }


    &-panel {

      width: @ai-panel-width;
      z-index: 999999;
      position: absolute;
      right: 0;
      height: 100%;
      background-color: @ai-panel-bg;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .23);

      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &-slide {
        &-transition {
          transition: all .3s ease;
        }
        &-enter,
        &-leave {
          right: -1 * @ai-panel-width - 10px;
        }
      }

      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: white;
        &-title {
          padding-left: 15px;
          flex-grow: 1;
          &.clickable {
            cursor: pointer;
            &:hover {
              color: @ai-link-color;
            }
          }
          h2 {
            float: left;
            margin: -2px 0 0 0;
          }
          .back-icon {
            float: left;
            margin-right: 10px;
          }
        }
        .close-panel {
          width: 50px;
          height: 100%;
          border-left: 1px solid @ai-panel-bg;
          cursor: pointer;
          background: transparent url('/_dist/common/img/aino/close-icon.svg') no-repeat center center;
          &:hover {
            background-color: @ai-panel-bg;
          }
        }
      }

      &-body {

        flex-grow: 1;
        overflow: auto;
        position: relative;
        padding: @ai-body-padding;

        display: flex;
        flex-direction: column;

        // messageMixin defines the base styles of all messages
        .messageMixin() {
          background: white;
          margin-bottom: 10px;
          width: auto;
          padding: @ai-body-padding;
          text-align: left;
          position: relative;
          box-shadow: @ai-message-box-shadow;
          border-radius: @ai-message-border-radius;
          float: left;
          .clearfix;

          span {
            &.critical {
              color: @ai-color-critical;
            }
            &.upgrade {
              color: @ai-color-upgrade;
            }
            &.improvement {
              color: @ai-color-improvement;
            }
            &.ignored {
              color: @ai-color-ignored;
            }
            &.passed {
              color: @ai-color-passed;
            }
          }

          .ai-message-help {
            display: block;
            box-sizing: border-box;
            padding: @ai-body-padding @ai-body-padding 0 @ai-body-padding;
            width: ~'calc(100% + 30px)';
            border-top: 2px solid @ai-panel-bg;
            margin-top: @ai-body-padding;
            margin-left: -1 * @ai-body-padding;
            margin-right: -1 * @ai-body-padding;
            color: #868686;
            .icon {
              float: left;
              font-size: 18px;
              color: #cacaca;
            }
            .read-more {
              float: right;
              text-align: right;
              text-transform: uppercase;
            }
            &:hover {
              color: @ai-link-color;
              .icon {
                color: @ai-link-color;
              }
            }
          }

        }

        .messages {
          flex-grow: 1;
          font-size: 15px;
          font-weight: 300;

          .ai-status {
            &-notification {
              left: @ai-body-status-size - 5px;
              z-index: 100;
            }
            &-image {
              position: absolute;
              top: @ai-body-padding;
              left: @ai-body-padding;
              width: @ai-body-status-size;
              height: @ai-body-status-size;
              background-size: @ai-body-status-size - 5px;
              text-align: center;

              &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                left: 3px;
                width: 46px;
                border-radius: 13px;
                height: 38px;
                box-shadow: 0 10px 25px rgba(0, 0, 0, .3);
              }

              &-marker {
                background-size: 16px;
                width: 20px;
                height: 30px;
                margin-top: -21px;
                margin-left: -4px;
              }
            }
          }

          .ai-message {
            .messageMixin;
            &.first {
              margin-left: @ai-body-status-size + 12px;

              &:before {
                content: '';
                position: absolute;
                //box-shadow: rgba(0, 0 , 0, .2) -1px 2px 2px;
                transform: rotate(45deg);
                top: 20px;
                left: -10px;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent white transparent;
              }
            }

            &.result {
              width: 100%;
              .icon {
                margin-right: 8px;
              }
              ul {
                padding-left: 20px;

                li {
                  padding: 5px;
                }
              }
            }

          }

          .list-suggestions {



            .ai-message.first {
              margin-bottom: 20px;
              opacity: 0;
              animation-name: smallFadeInFromLeft;
              animation-timing: ease-in;
              animation-duration: .3s;
              animation-fill-mode: forwards;
            }

            .ai-category {
              margin-top: 32px;
              margin-bottom: 20px;

              opacity: 0;

              animation-name: smallFadeInFromLeft;
              animation-duration: .3s;
              animation-timing: ease-in;
              animation-fill-mode: forwards;

              &:first-child {
                animation-delay: .1s;
              }
              &:nth-child(2) {
                animation-delay: .2s;
              }
              &:nth-child(3) {
                animation-delay: .3s;
              }
              &:nth-child(4) {
                animation-delay: .4s;
              }

              &-title {
                margin-bottom: 8px;
                &-header {
                  text-transform: uppercase;
                  opacity: .6;
                  a {
                    float: right;
                    span {
                      color: @tidy-rich-black;
                    }
                    .category-toggle-show {
                      display: none;
                    }
                    &.collapsed {
                      .category-toggle-show {
                        display: inline-block;
                      }
                      .category-toggle-hide {
                        display: none;
                      }
                    }
                  }
                }
              }

              .panel-collapse.in ~ .panel-collapse.empty {
                display: none;
              }

              .panel-body {
                padding: 0;
              }

              .list-group {
                box-shadow: @ai-message-box-shadow;
                border-radius: @ai-message-border-radius;
                margin-bottom: 0;

                .list-group-item {
                  padding: 16px;
                  border: 0;
                  cursor: pointer;
                  border-bottom: 2px solid @tidy-light-gray;
                  &:hover {
                    background-color: #f3f3f3;
                  }
                  &:first-child {
                    border-top-left-radius: @ai-message-border-radius;
                    border-top-right-radius: @ai-message-border-radius;
                  }
                  &:last-child {
                    border-bottom-left-radius: @ai-message-border-radius;
                    border-bottom-right-radius: @ai-message-border-radius;
                    border-botton: none;
                  }
                }

                .indicator {
                  @ai-indicator-size: 10px;
                  width: @ai-indicator-size;
                  height: @ai-indicator-size;
                  border-radius: @ai-indicator-size;
                  background-color: transparent;
                  display: inline-block;
                  margin-right: 5px;
                  position: absolute;
                  top: 21px;
                  &.critical {
                    background-color: @ai-color-critical;
                  }
                  &.upgrade {
                    background-color: @ai-color-upgrade;
                  }
                  &.improvement {
                    background-color: @ai-color-improvement;
                  }
                  &.ignored {
                    background-color: @ai-color-ignored;
                  }
                  &.passed {
                    background-color: @ai-color-passed;
                  }
                }

                .product-icon {
                  width: 40px;
                  box-shadow: 2px 2px 16px 0 #d9d9d9;
                  border-radius: 5px;
                  border: 1px solid #ececec;
                  position: absolute;
                  left: -9px;
                  top: 12px;
                }

                .category-name {
                  margin-left: 24px;
                  display: block;

                  &.upgrade {
                    margin-left: 32px;
                  }

                }

                .category-sub-title {
                    text-transform: uppercase;
                    font-size: 12px;
                    color: @tidy-dark-gray;
                    margin-left: 32px;
                    padding-top: 16px;
                    margin-bottom: 0;
                }

              }

            }

          }

        }

        .buttons {
          flex-shrink: 1;

          .ai-message-button {
            .messageMixin;
            width: 100%;
            background-color: #06b0fc;
            color: white;
            cursor: pointer;
            &:hover {
              background-color: #068ad4;
            }
          }

          .tooltip {
            font-size: 14px;
            &.top .tooltip-arrow {
              border-top-color: @ai-color-critical;
            }
            .tooltip-inner {
              background-color: @ai-color-critical;
              border-radius: 7px;
            }
          }

          &.analysis {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .ai-message-button {
              width: auto;
              text-align: center;
              &.grow {
                flex-basis: 30%;
              }
              &.unignore {
                background-color: @ai-color-ignored;
              }
              &.helpful.active {
                background-color: @ai-color-passed;
              }
              &.unhelpful.active {
                background-color: red;
              }

            }
          }

        }

      }

    }

  }

}
