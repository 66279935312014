@import (reference) "../../../node_modules/bootstrap/less/mixins";
@import (reference) "../../cms/less/_bootstrap-extend";
@import (reference) "../../cms/less/_variables";

// ------------------------------------
// Create site - Variables
// ------------------------------------

@default-padding: 20px;
@sidebar-width: 260px;

html {
  overflow: auto;
}

.create-site {

  // ------------------------------------
  // Create site - General
  // ------------------------------------

  font-size: 16px;

  img {
    max-width: 100%;
  }

  // ------------------------------------
  // Create site - Buttons
  // ------------------------------------

  .btn {
    /*
    height: auto !important;
    border-radius: 3px;
    box-shadow: none;
    border: 2px solid transparent;
    font-weight: 500;
    font-size: 1em;
    padding: 1.2em 1.6em 1.1em;
    transition: all .3s ease;
    */

    .inner-label {
      text-transform: uppercase;
      color: @gray;
      font-size: 0.8em;
      margin-right: 0.8em;
    }

    &.btn-link {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0;
      margin-bottom: 5px;
      i {
        //color: @tidy-brand;
        color: @tidy-rich-black;
        &.square-icon {
          line-height: 25px;
          height: 25px;
          width: 25px;
          //color: @tidy-brand;
          //border: 1px solid @tidy-brand;
          color: @tidy-rich-black;
          border: 1px solid @tidy-rich-black;
          border-radius: 5px;
        }
      }
    }

    .caret {
      float: right;
      margin-top: 10px;
    }

    &-outlined {
      border-color: @tidy-brand;
    }
    &-back {
      background: @gray-lighter;
      .back-icon {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }
    }

    &-add-page {
      width: 100%;
      margin-top: 1.8em;
      text-align: center;
      color: fadeout(@tidy-brand, 40%);
      transition: all .3s cubic-bezier(0.86, 0, 0.07, 1);
      .plus-icon {
        width: 11px;
        margin-right: 10px;
      }
    }

    &.btn-high {
      height: auto;
      padding: 1em 1.6em 1.1em;
    }

  }

  // ------------------------------------
  // Create site - Thin top navigation
  // ------------------------------------

  .top-nav {
    //border-bottom: 1px solid @gray-lighter;
    //background: @tidy-brand;
    //box-shadow: 0 3px 24px rgba(0,0,0,.1);
    margin-bottom: 0;
    color: @tidy-gray;
    font-size: 0.9em;
    padding: 16px 0 16px;
    .container {
      position: relative;
    }
    a {
      color: @tidy-gray;
      &.v-link-active {
        color: @tidy-rich-black;
        pointer-events: none;
      }
      &:hover {
        color: @tidy-link-color-hover;
      }
    }

    .dropdown-menu {
      a {
        color: @tidy-gray;
        &:hover {
          color: white;
        }
      }
    }

    &__breadcrumb {
      display: inline-block;
      width: 100%;
      &__content {
        display: none;
        @media (min-width: @screen-sm) {
          display: inline-block;
          line-height: 32px;
        }
      }
      &__logo {
        float: left;
        width: 100px;
        margin-right: 16px;
        margin-top: -4px;
        img {
          max-width: 100%;
          max-height: 50px;
        }
      }
      &__separator {
        color: lighten(@tidy-gray, 38%);
        margin: 0;
      }
      &__selected-theme {
        display: inline;
        padding: 0 10px;
        float: right;
        margin: 0;
      }
      &__is-template-preview {
        display: inline;
      }
    }
    &__profile {
      float: right;
      padding-top: 0;
      a {
        line-height: 32px;
      }
      > a {
        margin-left: 10px;
        display: inline-block;
      }
      .btn-group.open .dropdown-toggle {
        box-shadow: none;
      }
      &__picture {
        //float: right;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        //position: absolute;
        //right: 22px;
        box-shadow: 0 0 12px rgba(0, 0, 0, .15);
        top: -5px;
      }
      &__letters {
        background: @tidy-brand;
        border-radius: 3px;
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 0.1em;
        display: inline-block;
        text-align: center;
        width: 32px;
        margin: 0;
        height: 32px;
        line-height: 32px;
        box-shadow: @tidy-box-shadow;
        color: white;
        cursor: pointer;
      }
      &__email {
        display: none;
      }
      @media (min-width: @screen-sm) {
        &__email {
          display: inline;
          padding-right: 7px;
        }

        &__picture {
          right: 22px;
        }
      }
    }
    @media (min-width: @screen-sm) and (max-width: @screen-lg) {
      .container {
        width: 100%;
      }
    }
  }

  // ------------------------------------
  // Create site - Control bar
  // ------------------------------------

  .fixed-header {
    margin: 0 0 @default-padding;
    background: #ffffff;
    padding: 0 0 0;
    width: 100%;
    //transition: box-shadow .4s ease;
    position: fixed;
    border-bottom: 1px solid @gray-lighter;
    .clearfix;

    top: 0;
    z-index: 10;
    //box-shadow: 0 8px 24px rgba(0, 0, 0, .10);

    &__show-hidden-controls {
      position: absolute;
      right: 40px;
      top: -2px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 1.2em 1.6em;
      cursor: pointer;
      &:hover,
      &:active {
        text-decoration: none;
      }
      @media only screen and (min-width: @screen-sm) {
        display: none;
      }
    }
  }

  // Swatches / Color groups

  .swatches {
    display: inline-block;
    vertical-align: baseline;
    &__swatch {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 -1px;
    }
  }

  // ------------------------------------
  // Create site - Control bar - Selectors
  // ------------------------------------

  .selectors {
    margin-bottom: 10px;
    &__next,
    &__prev {
      .make-xs-column(6);
      .make-sm-column(2);
      text-align: right;
      .btn {
        width: 100%;
        margin: 0 2% 0 0;
        &:last-child {
          margin-right: 0;
        }
      }

    }
    &__prev {
      padding-right: 10px;
      @media only screen and (min-width: @screen-sm) and (max-width: 880px) {
        .make-sm-column(1);
        .btn {
          padding-left: 0;
          padding-right: 0;
          width: 100%;
          span {
            display: none;
          }
          .back-icon {
            margin-right: 0;
          }
        }
      }
      @media only screen and (max-width: @screen-sm) {
        margin-bottom: @default-padding / 2;
      }
    }
    &__next {
      padding-left: 10px;
      float: right;
      @media only screen and (min-width: @screen-sm) and (max-width: 880px) {
        .make-sm-column(3);
      }
      @media only screen and (max-width: @screen-sm) {
        position: absolute;
        bottom: 0;
        top: @default-padding*3 - 2;
        right: 0;
        width: 50%;
      }
    }

    &__theme {
      &__dropdown-menu {
        li {
          width: 50%;
          float: left;
          text-align: center;
          @media only screen and (min-width: @screen-sm) {
            width: 50%;
          }
          @media only screen and (min-width: @screen-lg) {
            width: 33%;
          }
        }
      }
    }

    &__collapse-wrapper {
      @media only screen and (max-width: @screen-sm) {
        display: none;
        &.open {
          display: block;
        }
      }
    }

    &__content,
    &__theme {
      .make-xs-column(12);
      @media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
        width: 50%;
      }
      .make-sm-column(6);
    }

    &--has-next-prev {
      .selectors__theme,
      .selectors__typography {
        .make-xs-column(12);
        @media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
          width: 50%;
          margin-top: 1em;
        }
        .make-sm-column(4);

      }
      @media only screen and (min-width: @screen-sm) and (max-width: 880px) {
        .selectors__theme {
          padding-left: 40px;
          padding-right: 10px;
        }

        .selectors__prev .btn {
          width: 60px;
        }
      }
    }

    @media (min-width: @screen-sm) and (max-width: @screen-lg) {
      .container {
        width: 100%;
      }
    }

  }

  // ------------------------------------
  // View selector
  // ------------------------------------

  .view-mode {
    &__item {
      display: inline-block;
      font-size: 0.75em;
      width: 68px;
      text-align: center;
      opacity: 0.4;
      transition: opacity 0.3s ease, box-shadow 0.3s;
      cursor: pointer;
      padding-bottom: 8px;
      margin: 0 4px;
      //color: @tidy-brand;
      color: @tidy-rich-black;
      img {
        height: 24px;
        margin: 12px 0 6px;
      }
      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
      &:active {
        opacity: 1;
      }
      &--active {
        opacity: 1;
      }
    }
    &__exit-fullscreen {
      display: none;
      background: #ffffff;
      border-radius: 3px;
      color: @tidy-brand;
      padding: 1.4em 1.5em;
      position: absolute;
      text-align: center;
      box-shadow: 0 2px 24px rgba(0, 0, 0, .15);
      img {
        height: 29px;
        margin: 0 0 0;
      }
    }
    &--right {
      position: absolute;
      right: 20px;
      top: 20px;
      @media only screen and (max-width: @screen-sm) {
        position: static;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    &--compact {
      .view-mode__item {
        width: 32%;
        margin: 0;
      }
    }
  }

  // ------------------------------------
  // Create site - preview cards
  // ------------------------------------

  .templates {
    margin-top: 148px;

    opacity: 0.5;
    animation-name: smallFadeInFromBottom;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    .container {
      position: relative;
    }

    &__header {
      text-align: center;
      margin-bottom: @default-padding*3;
      padding: 0 @default-padding;
      @media only screen and (max-width: @screen-sm) {
        margin-bottom: @default-padding*2;
      }
    }

    &__card {
      .make-xs-column(12);
      @media only screen and (min-width: 500px) {
        width: 50%;
      }
      .make-sm-column(4);
      .make-md-column(4);
      .make-lg-column(3);
      text-align: center;

      &__name {
        display: block;
        margin: @default-padding 0 @default-padding*2;
      }

      &__content {
        display: block;
        padding: @default-padding;
        background: #ffffff;
        border: 3px solid #ffffff;
        box-shadow: 0 4px 32px rgba(0, 0, 0, .10);
        border-radius: 9px;
        overflow: hidden;
        position: relative;
        margin-bottom: @default-padding / 2;
        width: 100%;
        transition: border-color 0.35s ease, transform 0.35s ease, width 0.45s @tidy-ease-heavy-in, box-shadow 0.45s cubic-bezier(0.86, 0, 0.07, 1), padding 0.45s cubic-bezier(0.86, 0, 0.07, 1);

        &:after {
          content: '';
          position: absolute;
          display: block;
          bottom: @default-padding;
          left: 0;
          width: 100%;
          height: @default-padding * 3;
          opacity: 0;
          transition: opacity .2s ease;
          background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }
      }

      &__image {
        overflow: hidden;
        height: 420px;
        &--desktop,
        &--mobile {
          transition: opacity .3s;
        }
        &--desktop {
          display: block;
        }
        &--mobile {
          display: none;
        }

        // Hide overflow scrollbar
        &::-webkit-scrollbar {
          width: 0;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        .templates__card__content {
          transform: translateY(-6px);
          box-shadow: 0 8px 38px rgba(0, 0, 0, .11);
          cursor: pointer;
          text-decoration: none;
          &:after {
            opacity: 1;
          }
        }
        .templates__card__content {
          text-decoration: none;
        }
      }
      &:active {
        .templates__card__content {
          border-color: @tidy-brand;
        }
      }

      // Adjust preview card image height on different screen sizes.

      @media only screen and (min-width: 500px) {
        &__image {
          height: 250px;
          //overflow: auto;
        }
      }
      @media only screen and (min-width: @screen-md) {
        &__image {
          height: 280px;
        }
      }
      @media only screen and (min-width: @screen-lg) {
        &__image {
          height: 290px;
        }
      }

    }

    &--mobile-view {
      .templates__card__content {
        width: 50%;
        margin: 0 auto 40px;
        padding: 20px 8px;
      }
      .templates__card__image {
        height: 180px;
        &--desktop {
          display: none;
        }
        &--mobile {
          display: block;
        }
      }
    }

    .coming-up {
      â€¨
      .templates__card__content {
        margin-top: 22px;
        padding-top: 25px;
        box-shadow: none;
        border: 1px dashed @tidy-light-gray;
        color: @tidy-gray;
        .fa {
          font-size: 63px;
          opacity: 0.2;
          margin-top: 16px;
        }
        .import {
          font-size: 20px;
          margin-top: 24px;
          margin-bottom: 16px;
          font-weight: 300;
        }

        .not-ready {
          margin: 0 8px 10px;
        }

      }

      &.templates__card:hover .templates__card__content {
        transform: none;
        cursor: default;
      }

    }

    @media only screen and (max-width: @screen-sm) {
      margin-top: 80px;
    }
  }

  .preview {
    margin-top: 68px;

    opacity: 0.5;
    animation-name: fadeInFromRight;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    z-index: 1;

    &__frame {
      width: 100%;
      float: left;
      background: @gray-lighter;
      border-radius: 6px;
      height: ~'calc(100vh - 142px)';

      iframe {
        display: block;
        width: 100%;
        height: ~'calc(100vh - 142px)';
        border: 0;
        border-radius: 6px;
        box-shadow: 0 2px 24px rgba(0, 0, 0, .15);
        &.mobile-view {
          border-radius: 20px;
          width: 420px;
          margin: 3vh auto;
          border: 20px solid #ffffff;
          border-top-width: 60px;
          border-bottom-width: 60px;
          max-height: 720px;
        }

      }

      &__container {
        position: relative;
        &.fullscreen-mode {
          position: static;
          iframe {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 0;
            width: 100vw;
            height: 100vh;
          }
          .view-mode__exit-fullscreen {
            display: block;
            top: 10px;
            left: 10px;
          }
        }
      }
    }

    @media only screen and (min-width: @screen-sm) {
      padding: 68px 20px;
    }

    @media only screen and (min-width: @screen-sm) {
      margin-top: 20px;
      &__frame {
        width: ~'calc(100% - 280px)'; // 280px = sidebar width / doesn't accept variables >:(
        height: ~'calc(100vh - 110px)';
        iframe {
          height: ~'calc(100vh - 110px)';
        }
      }

    }
  }

  .sidebar {
    float: right;
    width: 100%;
    //display: none;
    padding: 0 @default-padding;
    font-size: 0.9em;
    position: relative;

    h4 {
      border-bottom: 1px solid @gray-lighter;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    .btn-next {
      display: block;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .back-button {
      width: 100%;
      display: block;
      position: relative;
      margin-bottom: 32px;
      .icon {
        position: absolute;
        left: 16px;
        margin-top: 4px;
      }
    }


    &__template-name {
      font-weight: 400;
      margin: 0 0 24px;
      font-size: 24px;
    }

    &__pages {
      margin-top: @default-padding*1;
      ul {
        list-style: none;
        margin: 1.1em 0 0;
        padding: 0;
      }

      &__remove-button {
        display: inline-block;
        background: @tidy-brand;
        position: absolute;
        right: 0;
        width: 32px;
        height: 32px;
        margin-top: 4px;
        border-radius: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 32px;
        padding: 0 8px;
        font-size: 1em;
        text-transform: uppercase;
        font-weight: bold;
        transition: all .3s ease;
        overflow: hidden;
        .undo-text {
          display: none;
        }
        &:hover,
        &:active,
        &:focus {
          background: @tidy-brand;
          color: #ffffff;
        }
        &:after {
          content: '';
          background: #ffffff;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          display: block;
          width: 80px;
          opacity: 0;
          border-radius: 0;
        }
      }

      &__item {
        transition: opacity 0.5s ease-out;
        height: 44px;
        line-height: 44px;
        margin-left: -10px;
        position: relative;
        padding-left: 34px;
        color: fadeout(@tidy-brand, 30%);
        cursor: pointer;
        &--active {
          font-weight: bold;
          color: @tidy-brand;
        }
        &:hover {
          color: @tidy-brand;
        }
        &:before {
          content: '';
          display: block;
          margin-right: 16px;
          background: url('../../assets/img/page-icon.svg') no-repeat;
          width: 13px;
          position: absolute;
          height: 16px;
          left: 10px;
          top: 12px;
          background-size: 13px 16px;
        }
        &.do-undo {
          opacity: 0.8;
          color: @gray;
          .sidebar__pages__remove-button {
            width: 80px;
            background: #000000;
            border-color: #000000;
            color: #ffffff;
            border-radius: 0;
            &.animate-undo-progress {
              &:after {
                animation-name: animate-bar;
                animation-duration: 8s;
                animation-fill-mode: forwards;
              }
            }
          }
          .undo-text {
            display: inline;
          }
          .remove-icon {
            display: none;
          }
        }
      }
    }

    .drop-area {
      height: 100px;
      border: 2px dotted @tidy-brand;
      border-radius: 20px;
      margin-top: 1.8em;
      text-align: center;
      padding-top: 38px;
      position: relative;
    }

    @media only screen and (min-width: @screen-sm) {
      display: block;
      width: @sidebar-width;
    }

  }

  .add-pages-box {
    display: none;
    position: absolute;
    z-index: 10;
    left: -1px;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 40px;
    overflow-y: scroll;
    background: #ffffff;
    border-radius: 0;
    opacity: 0;
    .btn-close-modal {
      position: absolute;
      left: 40px;
      top: 40px;
    }
    &:after {
      content: '';
      width: 24px;
      height: 24px;
      transform: rotate(45deg);
      position: absolute;
      left: -12px;
      top: 80px;
      background: #ffffff;
      display: block;
    }
    .item {
      .make-md-column(6);
      .make-lg-column(4);
      margin: 20px 0;
      .draggable-object {
        height: 140px;
        width: 100%;
        display: block;
        background: @gray-lighter;
      }
    }
  }

  @keyframes animate-bar {
    0% {
      transform: translateX(-80px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: .9;
    }
  }


  // ------------------------------------
  // Create Modal specials
  // ------------------------------------

  .modal.create-modal {

    .site-url-preview {
      span {
        display: inline;
        margin: 0;
        font-size: @tidy-font-size-xl;
        color: @text-color;
        &.placeholder {
          color: @tidy-gray;
        }
      }
    }

  }

  .modal.team-modal {
    .invite-form {
      display: flex;
      .grow {
        flex-grow: 1;
      }
      .form-group {
        padding: 2px;
      }
    }
  }

}
