@import (reference) '_variables';

.editor-panel {
  flex-grow: 1;
  flex-direction: column;
  display: flex;

  .file-panel {
    margin-bottom: 0;

    .ace_editor {
      flex-grow: 1;
      .ace_search_field {
        color: black;
      }
    }

    .status-bar {
      display: flex;
      flex-direction: row;
      flex-shrink: 1;
      justify-content: space-between;

      .breadcrumbs {
        flex-grow: 1;
      }

      .status {
        padding-top: 14px;

        .label {
          margin-left: 8px;
        }

      }

      .buttons {
        padding-left: 16px;
        padding-right: 16px;

        .btn-lg {
          padding-left: 8px;
          padding-right: 8px;
        }

      }
    }

    .image-editor {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .image-file {
        max-width: 100%;
        max-height: 100%;
      }
    }

  }

  &-info {
    width: 100%;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px 20px 5px 20px;
    &.readonly {
      background-color: @brand-warning;
    }
    &.shared {
      background-color: @tidy-shared-dark;
    }
  }

}

.file-tree {
  li.team {
    background-color: @tidy-shared-light;
    border-radius: 5px;

    .active {
      background-color: @tidy-shared-dark;
      &::before {
        background-color: @tidy-shared-dark;
      }
      &::after {
        background-color: @tidy-shared-dark;
      }
    }

  }
}

.file-panel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border-radius: @tidy-border-radius;
  box-shadow: @tidy-box-shadow-lg;
  overflow-y: auto;

  .breadcrumbs {
    padding: 16px;
  }

  &.grid {

    .grid-toolbar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .breadcrumbs {
        flex-grow: 1;
      }
      .grid-tools {
        margin: 0;
        padding: 0;

        .btn-toolbar {
          background: transparent;
          border: 0;
          padding: 20px;
          margin: 0;
          color: white;
          &:hover {
            color: lighten(@brand-primary, 20%);
          }
          .icon {
            padding-left: 8px;
          }
        }

      }
    }

    ul,
    ol {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      list-style: none;
      padding: 0;

      li {
        width: 160px;
        margin: 15px;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;

        .thumb-wrap {
          padding: 16px;
          border-radius: 4px;
          background-color: lighten(@tidy-rich-black, 3%);
          margin-bottom: 4px;

          .thumb {
            height: 128px;
            line-height: 128px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            font-size: 64px;
          }

        }

        &.active {

          .thumb-wrap {
            background-color: @tidy-dark-gray;
          }

        }

        &:hover {

          .thumb-wrap {
            background-color: lighten(@tidy-rich-black, 6%);
          }

        }

      }

    }
  }

}

.file-editor {
  background-color: @tidy-rich-black;
  width: 100%;
  margin: 0 16px 16px 16px;
  border-radius: 4px;

  &.edit {
    margin-top: 16px;
  }

  .fg-list {
    margin-top: 24px;

    .fg-item {
      margin: 10px;
      display: block;
      float: left;
      width: 200px;
      position: relative;
      cursor: pointer;

      .fg-preview {
        text-align: center;
        height: 125px;
        background-color: #3c3c3c;
        vertical-align: middle;
      }

      .fg-item-label {
        float: left;
        width: 100%;
        padding: 10px;

        i {
          margin-top: 4px;
          float: left;

        }

        .fg-text {
          margin-left: 10px;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 150px;
          display: block;
          margin-top: 2px;
          color: @tidy-light-gray;
        }

      }

      &.file {

        &.image {

          .fg-preview {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            i {
              font-size: 40px;
              color: #6f6f6f;
              margin-top: 40px;

            }

          }

        }

      }

    }

  }

  .single-image {
    margin: auto;
    display: block;
  }

}

.file-empty-right {
  text-align: center;
  font-weight: 200;
  margin-top: 40vh;
}

.upload-dropzone {
  width: 100%;
  padding: 0;
  text-align: center;
  background-color: @tidy-background-light-gray;
  font-weight: 300;
  float: left;
  margin-bottom: 16px;
  cursor: pointer;

  .text {
    padding: 64px 16px;
    font-size: @tidy-font-size-xl;

    strong {
      font-weight: 400;
    }

  }

  &.dz-started {
    .text {
      display: none;
    }
    .dz-error-mark {
      display: none;
    }
  }

  .dz-image {
    width: 50px;
    float: left;
    margin-right: 8px;
  }

  .dz-preview {
    float: left;
    margin-bottom: 8px;
    width: 100%;
    border-bottom: 1px solid @tidy-border-color;
    padding: 24px 16px;

    &:last-child {
      border: 0;
    }

    a {
      float: right;
      line-height: 50px;
      cursor: pointer;

      &.dz-remove {
        display: none;
      }

    }

    .progress {
      width: 100%;
      height: 10px;
      margin-bottom: 8px;
      margin-top: 8px;
      float: left;
    }

    .dz-details {
      float: left;
      width: 220px;
      text-align: left;

      .dz-image {
        float: left;
        width: 50px;
      }

      .dz-filename {
        margin-top: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dz-size {
        strong {
          font-weight: 300;
        }
      }

    }

    .dz-error-mark {
      float: left;
    }

    .dz-error-message {
      width: 210px;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 13px;
      text-align: left;

      span {
        line-height: 20px;
        color: #9e0202;
      }

    }

  }

}
