@import '_variables';

.settings {

    .layout-side.left {
        flex-basis: 400px;
        .v-link-active {
            font-weight: bold;
        }
    }

    .app-preview-disable {
        opacity: 0.6;
        pointer-events: none;
    }

    .layout-center {

        h2 {
            font-size: 24px;
            .top-space {
                margin-top: 48px;
            }
        }

        .subheading {
            font-size: 16px;
            margin: 16px 0 32px 0;
        }

        table.site-users {
            width: 100%;
            max-width: 1000px;

            th,
            td {
                padding: 8px;
            }

            td {
                border: 1px solid @tidy-light-gray;

                &.icons {
                    text-align: center;

                    i {
                        margin: 0 8px;
                    }

                }

            }


        }

        a {
            color: @tidy-brand;
        }

        .container {
            margin-left: 0;
            padding-left: 15px;
            max-width: 820px;
        }

        &.hosting {

            .primary-domain {
                display: flex;
                align-items: flex-end;
                padding: 0;

                .dot {
                    color: @tidy-gray;
                    font-size: 32px;
                    padding-bottom: 7px;
                }

                .form-control {
                    background-color: transparent;
                    border: 0;
                    box-shadow: none;
                    flex-basis: 100px;
                    flex-shrink: 0;
                    padding-left: 0;
                    padding-right: 0;

                    &.sld {
                        flex-basis: 100%;
                        flex-shrink: 1;
                    }

                }

            }

        }

        .domain-config {

            .clearfix();
            padding-bottom: 50px;

            h2 {
                margin-top: 24px;
            }

            .config-title {
                margin-top: 24px;
                &.first {
                    margin-top: 42px;
                }
                h2 {
                    margin-top: 0;
                    float: left;
                }
                .save-config-btn {
                    float: right;
                }
                .clearfix();
            }

            .pagespeed {
                tr.filter {
                    .filter-doc-link {
                        opacity: 0;
                        transition: all .3s ease;
                        .icon {
                            margin: 10px;
                        }
                    }
                    &:hover {
                        .filter-doc-link {
                            opacity: 1;
                        }
                    }
                }
            }

        }

        .steps {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid @tidy-border-color;
            font-size: @tidy-font-size-lg;
            margin-bottom: 64px;
            color: @tidy-gray;

            .step {
                background-color: @tidy-background-light-gray;
                padding: 0 16px;
                line-height: 2em;
                margin-bottom: -1em;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.selected {
                    color: @tidy-rich-black;
                }

                &.link {
                    cursor: pointer;

                    &:hover {
                        color: @tidy-rich-black;
                    }

                }

            }

        }

        .product-list {

            @media (min-width: @screen-sm-max) {
                display: flex;
            }

            .app {
                display: flex;

                .border {
                    border: 1px solid @tidy-light-gray;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;

                    img {
                        max-width: 100%;
                    }

                    .text {
                        padding: 15px;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        h3 {
                            font-size: 22px;
                            margin-top: 20px;
                            margin-bottom: 0;
                        }

                        .app-subheading {
                            font-size: 15px;
                        }

                        .btn {
                            margin-top: 24px;
                        }

                        .large-price {
                            font-size: 48px;
                            display: inline;
                        }

                        .small-price {
                            font-size: 12px;
                            display: inline;
                        }

                        .billing-period {
                            font-size: 16px;
                        }

                        .description {
                            margin-top: 48px;
                            margin-bottom: 48px;
                            font-size: 20px;
                            flex-grow: 1;
                        }

                    }

                }

                * {
                    text-align: center;
                }

            }

        }

        .host-logo {
            float: left;
            padding: 20px 40px;
            line-height: 60px;
        }

        &.pagespeed {
            .mb4 {
                margin-bottom: 40px;
            }
            .score-table {
                &:extend(.table all);
                &:extend(.table-striped all);
                &:extend(.table-hover all);
                text-align: center;
                th {
                    text-align: center;
                    .fa {
                        font-size: 2em;
                    }
                }
                td {
                    font-size: 1.1em;
                    &.text-small {
                        font-size: .9em;
                    }
                }
            }
        }

    }

    .redirect-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .form-group {
            width: 40%;
        }
        .icon {
            margin-top: -15px;
            color: #9c9c9c;
        }
        .btn {
            margin-top: -15px;
        }
        .fixed {
            width: 40%;
            font-family: monospace;
        }

    }
}
