// lesshint idSelector: false

@import '_variables';
@import '../../../node_modules/toastr/toastr';

#toast-container {

    > div {
        opacity: 1;
    }

}
