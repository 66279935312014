@import '_variables';

.image-modal {

  .modal-content {
    padding: 0;
  }
  .modal-footer {
    margin: 0;
    padding: 24px 32px 16px;
  }

  .folder-tree {
    width: 300px;

    .upload {
      display: block;
      border-bottom: 1px solid @tidy-border-color;
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px 16px 16px;
      text-align: center;

      .icon {
        margin-right: 8px;
      }

    }

    .upload-dropzone {
      background-color: white;
    }

  }

  .image-grid {
    width: 360px;

    ul,
    ol {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      list-style: none;
      padding: 0;

      li {
        width: 142px;
        margin: 8px;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;

        .thumb-wrap {
          padding: 8px;
          border-radius: 4px;
          background-color: white;
          margin-bottom: 8px;
          box-shadow: 0 0 16px rgba(0, 0, 0, .1);

          .thumb {
            height: 128px;
            line-height: 128px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            font-size: 64px;
          }

        }

        &.active {
          color: @tidy-brand;

          .thumb-wrap {
            box-shadow: inset 0 0 0 2px @tidy-brand;
          }

        }

        &:hover {

          .thumb-wrap {
            background-color: @tidy-light-gray;
          }

        }

      }

    }

  }

  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .image-preview {
      flex-grow: 1;
      position: relative;
      //overflow-y: auto;

      .image-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        box-shadow: 0 4px 36px rgba(0,0,0,.15), 0 3px 6px rgba(0,0,0,.1);
        text-align: center;

        img {
          max-height: 480px;
        }

      }

      .info-text {
        box-shadow: none;
      }

    }

    .image-settings {
      border-top: 1px solid @tidy-border-color;
      margin-left: -32px;
      margin-right: -32px;
      padding: 16px 32px 0;
    }

  }

}
