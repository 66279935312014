@import '_variables';

.material {
  &.input-group {

    .input-group-addon {
      background: transparent;
      border: 0;
      color: @text-color;
    }
    .form-control {
      background: transparent;
      border: 0;
      box-shadow: none;
    }

  }
  &__filter {
    border-bottom: 2px solid transparent;

    &.input-group {

      .input-group-addon {
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
        color: @tidy-light-gray;
        &.search {
          cursor: text;
        }
        &.clear {
          color: transparent;
        }
      }

      .form-control {
        padding-left: 3px;
        padding-right: 3px;
      }

    }

    &:hover,
    &.active {
      border-bottom: 2px solid @tidy-brand;

      &.input-group {
        .input-group-addon {
          &.clear {
            color: @tidy-light-gray;
          }
        }
      }

    }

  }

}
