@import '_variables';

&.layout-center {
    flex-direction: column;
    flex-grow: 1;
    order: 2;
    padding-top: 16px;
    min-width: 400px;
    background-color: @tidy-center-background-color;

    &.white {
        background: white;
    }

    .center-top {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-shrink: 0;

        .collapse-side {
            flex: 0 0 24px;
            line-height: 39px;
            text-align: center;
            border-top: 1px solid @tidy-border-color;
            border-bottom: 1px solid @tidy-border-color;
            cursor: pointer;
            color: @tidy-gray;

            &.left {
                border-right: 1px solid @tidy-border-color;
                border-radius: 0 3px 3px 0;
                order: -99;
                padding-right: 4px;
            }

            &.right {
                border-left: 1px solid @tidy-border-color;
                border-radius: 3px 0 0 3px;
                order: 99;
                padding-left: 4px;
            }

            &.closed {
                background-color: @tidy-dark-gray;
                border-color: @tidy-dark-gray;

                .icon {
                    transform: rotate(180deg);
                    color: white;
                }
            }

        }

        .top-content {
            width: 100%;
            flex-grow: 1;
            margin-left: 16px;
            margin-right: 16px;

            &.tabs {
                display: flex;
                overflow-x: auto;
                overflow-y: visible;
                white-space: nowrap;
                font-size: @tidy-font-size-sm;

                .tab {
                    margin-left: 4px;
                    margin-right: 4px;
                    position: relative;
                    user-select: none;

                    .tab-modified {
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        z-index: 2;
                        transition: color .2s;
                    }

                    .tab-category {
                        position: absolute;
                        top: 4px;
                        left: 24px;
                        z-index: 2;
                        transition: color .2s;
                        right: 24px;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .tab-has-errors {
                        position: absolute;
                        top: 4px;
                        right: 8px;
                        z-index: 2;
                        color: @brand-danger;
                        transition: color .2s;
                    }

                    .tab-close {
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding-left: 4px;
                        padding-right: 16px;
                        z-index: 3;
                        transition: color .2s;
                        cursor: pointer;
                        height: 48px;
                        line-height: 48px;

                        &:hover {
                            color: red;
                        }

                    }

                    .tab-title {
                        background-color: white;
                        border-radius: 4px 4px 0 0;
                        padding-left: 16px;
                        padding-right: 32px;
                        z-index: 1;
                        cursor: pointer;
                        min-width: 32px;
                        height: 48px;
                        line-height: 48px;
                        border-left: 1px solid @tidy-border-color;
                        border-right: 1px solid @tidy-border-color;
                        border-top: 1px solid @tidy-border-color;

                        &:hover {
                            background-color: darken(white, 5%);
                        }

                    }

                    .icon {
                        margin-left: 4px;
                    }

                    &.active {
                        color: white;
                        .tab-title {
                            background-color: @tidy-brand;
                            &:hover {
                                background-color: lighten(@tidy-brand, 5%);
                            }
                        }
                    }

                    &.team {
                        .tab-title {
                            background-color: @tidy-shared-light;
                            &:hover {
                                background-color: lighten(@tidy-shared-light, 5%);
                            }
                        }
                        &.active {
                            color: white;
                            .tab-title {
                                background-color: @tidy-shared-dark;
                                &:hover {
                                    background-color: lighten(@tidy-shared-dark, 5%);
                                }
                            }
                        }
                    }

                }

            }

            &.url-bar {
                margin-left: 16px;
                margin-right: 64px;

                .input-group {

                    //padding: 4px;
                    background-color: @tidy-background-light-gray;
                    border-radius: 3px;
                    box-shadow: inset 0 0 3px 0 fade(@tidy-rich-black, 15%), inset 0 6px 5px -5px fade(@tidy-rich-black, 15%);

                    .input-group-addon,
                    .form-control,
                    .btn-default {
                        background-color: transparent;
                        border: 0;
                        box-shadow: none;
                        font-size: 14px;
                    }

                    .input-group-addon {
                        color: @tidy-gray;
                        padding-right: 0;
                        font-weight: 300;
                    }

                    .form-control {
                        padding-left: 4px;
                        padding-right: 8px;
                        padding-top: 5px;
                    }

                    .icon {
                        font-size: @tidy-font-size;
                    }

                    .dropdown {
                        margin-bottom: 0;
                        border: 0;
                    }

                    .input-group-btn {

                        .icon {
                            position: relative;
                            top: 2px;
                        }

                    }

                }

            }

        }

        .top-buttons {
            flex-shrink: 0;
            margin-right: 16px;
            margin-top: -6px;

            .btn-link {
                color: @tidy-gray;
                // color: @btn-primary-bg;
                opacity: 0.5;
                cursor: pointer;
                height: 40px;
                position: relative;

                .fa,
                .amp-logo-svg {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .fa-mobile {
                    font-size: 1.6em;
                }

                &.active {
                    color: @tidy-rich-black;
                    // color: @btn-primary-bg;
                    opacity: 1;
                }

            }

            &-amp {
                .amp-logo-svg {
                    width: 24px;
                    .shape {
                        fill: @tidy-gray;
                    }
                }
                &.active {
                    .amp-logo-svg {
                        .shape {
                            fill: @amp-blue;
                        }
                    }
                }
            }
            &-mobile {
                width: 20px;
            }
            &-tablet {
                width: 32px;
            }
            &-desktop {
                width: 48px;
            }
            &-expand {
                width: 48px;
                &.btn-link .fa {
                    position: static;
                    margin: 6px 5px;
                }
            }

            .square-icon {
                line-height: @tidy-btn-height-sm;
                height: @tidy-btn-height-sm;
                width: @tidy-btn-height-sm;
                color: @tidy-rich-black;
                // color: @btn-primary-bg;
                border: 1px solid @tidy-gray;
                // border: 1px solid @btn-primary-bg;
                border-radius: 5px;
            }

        }

    }

    .center-main {
        flex-grow: 1;
        display: flex;
        padding-left: 16px;
        padding-right: 16px;
        // justify-content: space-around;

        .screen {
            position: relative;
            display: flex;
            width: 100%;
            box-shadow: @tidy-box-shadow-lg;
            border-radius: @tidy-border-radius-lg;
            transition: width .2s, height .2s;
            background-color: white;
            margin: 16px auto;

            iframe {
                width: 100%;
                border: 0;
                border-radius: 5px;

                &.disabled {
                    pointer-events: none;
                    opacity: .5;
                }
            }

        }

        .close-fullscreen {
            display: none;
        }

        &.mobile {
            align-items: center;

            .screen {
                height: 667px;
                width: 375px;
                max-height: ~'calc(100vh - 128px)';
            }

        }

        &.tablet {
            align-items: center;

            .screen {
                height: 1024px;
                width: 768px;
                max-height: ~'calc(100vh - 128px)';
            }

        }

        &.fullscreen {

            iframe {
                margin: 0;
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                max-height: none;
                z-index: 100;
                border-radius: 0;
                transition-property: none;
                background-color: white;
            }

            .close-fullscreen {
                display: block;
                position: fixed;
                top: 32px;
                left: 32px;
                height: 64px;
                width: 64px;
                line-height: 64px;
                text-align: center;
                background-color: white;
                z-index: 101;
                box-shadow: @tidy-box-shadow;
                cursor: pointer;
            }

        }

    }

    // Home / Analytics view things

    &.home {
        min-width: 600px;

        a {
            color: @tidy-brand;
            cursor: pointer;
        }
        .container {
            padding: 8px;
            margin: 0 auto;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding: 0;
        }
        .home-header {
            border-bottom: 1px solid @gray-lighter;
            padding-bottom: 2em;
        }
        .home-dropdown {
            .btn {
                text-align: left;
                padding-left: 16px;
                font-weight: 300;
                box-shadow: 0 3px 3px rgba(0, 0, 0, .06);
                .caret {
                  position: absolute;
                  right: 16px;
                  top: 22px;
                }
            }
            .compared-to {
                opacity: 0.5;
                float: right;
            }
            .dropdown-menu {
                width: 100%;
                a {
                    color: white;
                }
            }
        }
    }

    .analytics {
        margin-top: 1em;
        &-row {
            .make-row;
            margin: 0;
            width: 100%;
            padding: 3em 0;
            border-bottom: 1px solid @gray-lighter;


            &.analytics-success {
                .analytics-number-big,
                .analytics-number-icon {
                    color: @tidy-analytics-success;
                }
                .analytics-number-icon:before {
                    content: "\65";
                }
            }
            &.analytics-great-success {
                .analytics-number-big,
                .analytics-number-icon {
                    color: @tidy-analytics-success;
                }
                .analytics-number-icon:before {
                    content: "\66";
                }
            }
            &.analytics-warning {
                .analytics-number-big,
                .analytics-number-icon {
                    color: @tidy-analytics-warning;
                }
                .analytics-number-icon:before {
                    content: "\63";
                }
            }
            &.analytics-danger {
                .analytics-number-big,
                .analytics-number-icon {
                    color: @tidy-analytics-danger;
                }
                .analytics-number-icon:before {
                    content: "\64";
                }
            }

        }
        &-number {
            .make-md-column(12);
            .make-lg-column(6);
        }
        &-graph {
            .make-md-column(12);
            .make-lg-column(6);
            &-guide {
                font-weight: 300;
                opacity: 0.8;
                padding: 1.5em;
                text-align: center;
                width: 100%;
                display: block;
                position: relative;
                z-index: 2;
                padding-top: 0;
            }
            &-trend {
                display: block;
                width: 100%;
            }
        }
        &-number-container {
            margin-left: 4em;
        }
        &-number-icon,
        &-number-big {
            display: inline-block;
            font-size: 3.2em;
            font-weight: 300;
            color: @tidy-analytics-info;
        }
        &-visitors {
            .analytics-number-big {
                display: inline-block;
                font-size: 4.2em;
                font-weight: 300;
                line-height: 1.2em;
                color: @tidy-analytics-info;
            }
        }
        &-number-icon {
            float: left;
            font-family: "tidy-icons";
        }
        &-number-compared {
            font-size: 1.8rem;
            margin-left: 8px;
            line-height: 1.8rem;
            display: inline;
            color: @tidy-analytics-info;
            position: relative;
            &.positive,
            &.negative {
                &:before {
                    font-family: "tidy-icons";
                    display: block;
                    position: absolute;
                    top: -2rem;
                    left: -4px;
                    font-size: 22px;
                }
            }
            &.positive {
                color: @tidy-analytics-success;
                &:before {
                    content: "\70";
                }
            }
            &.negative {
                color: @tidy-analytics-danger;
                &:before {
                    content: "\71";
                }
            }
        }
        &-text {
            font-size: 1.1em;
            font-weight: 300;
        }
        @media screen and (min-width: 1600px) and (min-height: 850px) {
            font-size: 18px;
        }
    }


}
