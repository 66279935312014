@import '_variables';

.context-menu {
  background: @tidy-background-light-gray;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10001;
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1em;
  min-width: 192px;
  border-radius: @tidy-border-radius;
  box-shadow: @tidy-box-shadow-lg;
  border-top: 1px solid @tidy-border-color;
  border-left: 1px solid @tidy-border-color;
  border-right: 1px solid @tidy-border-color;

  li {
    padding: 0;
    margin: 0;
  }

  a {
    display: block;
    padding: 8px;
    cursor: pointer;
  }

}

.context-menu-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
