@import (reference) "../../../node_modules/bootstrap/less/mixins";
@import (reference) "../../cms/less/_bootstrap-extend";
@import (reference) "../../cms/less/_variables";
@import (reference) "../../cms/less/_loader";

.import-site {

  .loader {
    top: 65px;
  }

  &-slogan {
    margin-bottom: 50px;
    h1 {
      font-style: italic;
      &:before {
        content: '"';
      }
      &:after {
        content: '"';
      }
    }
  }

  .site-url-form {
    display: flex;
    &-input {
      flex-grow: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-start {
    height: ~"calc(100vh - 65px)";
    margin-top: 130px;
    text-align: center;

    .flash {
      &:extend(.loader .content .flash all);
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .btn-icon {
      min-width: 150px;
    }

  }

  &-analyze {

    &.layout-container {
      height: ~"calc(100vh - 65px)";
      margin-top: 65px;

      .layout-item {

        &.layout-side {

          .select-areas {
            .progress {
              margin-bottom: 5px;
            }
          }

          &.left {
            overflow: visible;

            .domtree {
              width: 350px;
              overflow-y: auto;
              overflow-x: auto;
              white-space: nowrap;


              .tree.dom {
                .item-text-wrap {
                  display: inline-block;
                }

                @color-dom-tree-navigation: lighten(#5bc0de, 20%);
                @color-dom-tree-content: lighten(#49d144, 20%);
                .navigation {
                  border-radius: 4px;
                  box-shadow: inset 0 0 0 1px @color-dom-tree-navigation;
                  background-color: @color-dom-tree-navigation;
                  &.active,
                  li.active {
                    background-color: lighten(@color-dom-tree-navigation, 20%);
                  }
                  &.hover,
                  li.hover {
                    box-shadow: inset 0 0 0 1px darken(@color-dom-tree-navigation, 20%);
                    background-color: darken(@color-dom-tree-navigation, 20%);
                  }
                }
                .content {
                  border-radius: 4px;
                  box-shadow: inset 0 0 0 1px @color-dom-tree-content;
                  background-color: @color-dom-tree-content;
                  &.active,
                  .active {
                    background-color: lighten(@color-dom-tree-content, 20%);
                  }
                  &.hover,
                  .hover {
                    box-shadow: inset 0 0 0 1px darken(@color-dom-tree-content, 20%);
                    background-color: darken(@color-dom-tree-content, 20%);
                  }
                }

              }

            }

            .popover {
              top: -16px;
              display: none;
              min-width: 220px;
              position: fixed;
              .arrow {
                top: 30.8%;
              }
              &-title {
                text-transform: none;
              }
              &-content {
                .btn-row {
                  padding-left: 0;
                  padding-right: 0;
                  margin-bottom: 5px;
                }
              }
            }

          }

          &.right {
            .side-content.template-group-box {

              max-height: 85vh;
              overflow: auto;

              .template-group {

                border-radius: 5px;
                border: 2px solid @tidy-light-gray;
                margin-bottom: 20px;

                &.active-group {
                  border-color: lighten(@tidy-brand, 20%);
                  h4 {
                    color: lighten(@tidy-brand, 10%);
                  }
                }

                h4 {
                  text-transform: none;
                  padding: 5px;
                  font-size: .8em;
                  color: @tidy-gray;
                }

                .tree {
                  margin-right: 0;
                  margin-left: 0;
                  padding-left: 0;
                  overflow: hidden;
                }

                .flex-link {
                  display: inline-block;
                  width: 100%;
                }
              }

            }
          }

        }

        &.layout-center {
          padding-top: 0;
          display: flex;

          iframe {
            border: 0;
            flex-grow: 1;
            flex-direction: column;
            width: 100%;
          }

        }

      }

    }

  }

}
