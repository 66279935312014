@import '_variables';

@tidy-app-statusbar-bg: #f5f5f5;

@tidy-small-app-icon-radius: 8px;
@tidy-small-app-icon-width: 48px;
@tidy-small-app-icon-height: 48px;
@tidy-small-app-icon-margin: 16px;
@tidy-small-app-icon-shadow: 0 1px 6px rgba(0, 0, 0, .15), 0 7px 16px rgba(0, 0, 0, .1);

@tidy-app-card-radius: 3px;
@tidy-app-card-shadow: 0 3px 6px rgba(0, 0, 0, .1);
@tidy-app-card-padding-sm: 16px;
@tidy-app-card-padding-lg: 28px;
@tidy-app-card-icon-size: 60px;
@tidy-app-card-icon-margin: 16px;
@tidy-app-card-icon-shadow: 0 1px 6px rgba(0, 0, 0, .15), 0 7px 16px rgba(0, 0, 0, .1);
@tidy-app-card-icon-radius: 8px;

// Large app view

@tidy-app-padding: 48px;
@tidy-app-icon-size: 80px;
@tidy-app-icon-radius: 8px;
@tidy-app-icon-margin: 24px;

// Small app cards - used in Home

a.small-app,
.small-app {
	display: block;
	color: @text-color !important; // lesshint importantRule: false
	&-icon {
		float: left;
		margin-right: @tidy-small-app-icon-margin;
		margin-bottom: @tidy-small-app-icon-margin;
		width: @tidy-small-app-icon-width;
		height: @tidy-small-app-icon-height;
		border-radius: @tidy-small-app-icon-radius;
		box-shadow: @tidy-small-app-icon-shadow;
	}
	&-header,
	&-category,
	&-text {
		margin-left: @tidy-small-app-icon-width + @tidy-small-app-icon-margin;
	}
	&-header {
		text-transform: none;
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 0.5em;
		transition: color .3s;
	}
	&-category {
		text-transform: none;
		font-size: 12px;
		opacity: 0.5;
		margin-bottom: 1em;
	}
	&-text {
		font-weight: 300;
		font-size: 14px;
	}
	&:hover,
	&:active,
	&:focus {
		.small-app-header {
			color: @tidy-brand;
		}
	}
}


.apps {

	@media (min-width: 1600px) {
		.container {
			width: 1570px;
		}
	}


	&-side {
		box-shadow: 0 0 12px rgba(0, 0, 0, .1);
		border-right: 0 !important;  // lesshint importantRule: false
		z-index: 2;
		.app-store-name a {
			margin-left: 5px;
			vertical-align: bottom;
			line-height: 24px;
		}
		h4 {
			opacity: 0.6;
		}
		&-filters {
			margin: 0;
			padding: 0 0 0 16px;
			list-style: none;
			&-item {
				font-size: 16px;
				font-weight: 300;
				margin-bottom: 1em;
				color: fade(@text-color, 80%);
				&.active a {
					color: @tidy-brand;
					font-weight: 400;
				}
				&:hover a,
				&:focus a,
				&:active a {
					color: fade(@tidy-brand, 80%);
				}
			}
		}
	}

	&-status {
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		font-size: 16px;
		font-weight: 300;
		width: 100%;
		position: fixed;
		z-index: 1;
		transform: translate3d(0, 0, 0);
		margin-top: -16px;
		background: fade(@tidy-app-statusbar-bg, 96%);
		&-searchresults {
			display: inline-block;
			padding: 16px 24px;
			border-right: 1px solid rgba(0, 0, 0, .1);
			color: fade(@text-color, 60%);
		}
		&-dropdown {
			display: inline-block;
			.btn {
				background: none;
				padding: 16px 24px;
				border-radius: 0;
				height: 100%;
				border: 0;
				border-right: 1px solid rgba(0, 0, 0, .1);
				font-weight: 300;
				font-size: 16px;
				display: inline-block;
				.caret {
					margin-left: 16px;
				}
			}
		}
	}

	&-center {
		&-container {
			width: 100%;
			padding: 72px 8px;
			@media (min-width: @screen-lg-min) {
				padding: 72px 48px;
			}
			&.app-page {
				width: 100%;
				padding: 16px 8px;
				@media (min-width: @screen-lg-min) {
					padding: 32px 48px;
				}
			}
		}
	}
}

.app-card {
	margin-bottom: 24px;
	.make-xs-column(6);
	.make-lg-column(4);


	&-content {
		height: 420px;
		background: white;
		border-radius: 3px;
		padding: @tidy-app-card-padding-sm;
		box-shadow: @tidy-app-card-shadow;
	}
	&-icon {
		float: left;
		margin-right: @tidy-app-card-icon-margin;
		margin-bottom: @tidy-app-card-icon-margin;
		width: @tidy-app-card-icon-size;
		height: @tidy-app-card-icon-size;
		border-radius: @tidy-app-card-icon-radius;
		box-shadow: @tidy-app-card-icon-shadow;
	}
	&-header,
	&-category {
		margin-left: @tidy-app-card-icon-size + @tidy-app-card-icon-margin;
	}
	&-header {
		text-transform: none;
		font-weight: 400;
		font-size: 16px;
		padding-top: 6px;
		margin-bottom: 0.5em;
		transition: color .3s;
	}
	&-texts {
		height: 200px;
		overflow-y: auto;
		clear: both;
	}
	&-footer {
		border-top: 1px solid rgba(0, 0, 0, .1);
	}
	&-category {
		font-size: 12px;
		text-transform: none;
		opacity: 0.5;
		margin-bottom: 1em;
	}
	&-ingress {
		clear: both;
		font-size: 14px;
	}
	&-description {
		font-weight: 300;
		font-size: 14px;
	}
	&-readmore {
		.btn-default;
		text-align: center;
		display: block;
		font-size: 16px;
		padding: 8px;

		border-radius: 3px;
		margin: 16px 0 4px;
	}
	&-try {
		position: absolute;
		margin-top: 8px;
		color: @tidy-brand;
	}
	&-pricing {
		display: block;
		width: 100%;
		text-align: right;
		font-size: 14px;
		padding: 8px;
		font-weight: 300;
		color: @gray;
		margin-bottom: 0;
		float: right;

	}

	&-bot-message {
		background: @tidy-brand;
		color: white;
		font-size: 12px;
		text-transform: uppercase;
		padding: 4px 6px 3px;
		border-radius: 2px;
		position: absolute;
		top: -10px;
		left: 48px;
	}

	&.bot-suggestion {
		.ai-container {
			left: 0;
			top: -12px;
			width: 42px;
			height: 42px;
			position: absolute;
		}
		.app-card-content {
			border: 3px solid fade(@tidy-brand, 40%);
			box-shadow: 0 3px 17px rgba(0, 0, 0, .2);
		}
	}
	@media (min-width: @screen-lg-min) {
		&-content {
			padding: @tidy-app-card-padding-lg;
		}
	}
	@media (min-width: 1600px) {
		width: 25%;
	}
}

.app-page {
	.app-card {
		width: 100%;

		&-content {
			height: auto;
			background: white;
			border-radius: 6px;
			padding: @tidy-app-padding;
			box-shadow: @tidy-app-card-shadow;
		}
		&-icon {
			float: left;
			margin-right: @tidy-app-icon-margin;
			margin-bottom: @tidy-app-icon-margin;
			width: @tidy-app-icon-size;
			height: @tidy-app-icon-size;
			border-radius: @tidy-app-icon-radius;
			box-shadow: @tidy-app-card-icon-shadow;
		}
		&-header,
		&-category {
			margin-left: @tidy-app-icon-size + @tidy-app-icon-margin;
		}
		&-header {
			text-transform: none;
			font-weight: 400;
			font-size: 18px;
			padding-top: 8px;
			margin-bottom: 0.5em;
			transition: color .3s;
		}

		&-title {
			.make-xs-column(12);
			.make-lg-column(8);

			&-container {
				.make-row;
				border-bottom: 1px solid rgba(0, 0, 0, .1);
				padding-bottom: 1em;
				margin-bottom: 2em;
			}
			&-buy {
				.make-xs-column(12);
				.make-lg-column(4);
				text-align: center;
			}
		}

		&-try {
			.btn;
			.btn-default;
			background: white;
			font-weight: 300;
			line-height: 28px;
			width: 100%;
			margin-bottom: 4px;
			border: 1px solid @tidy-brand;
			border-radius: 3px;
			position: static;
			display: block;
			font-size: 16px;
		}
		&-pricing {
			display: block;
			text-align: center;
			font-size: 14px;
		}
		&-category {
			font-size: 14px;
			text-transform: none;
			opacity: 0.5;
			margin-bottom: 1em;
		}
		&-ingress {
			clear: both;
			font-size: 18px;
		}
		&-description {
			font-weight: 300;
			font-size: 16px;
			h3 {
				font-weight: 500;
				margin-top: 2em;
			}
		}

	}
}
