@keyframes indicatorPulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1, 1);
    opacity: .4;
  }
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes flashAnimation {
  0% {
    transform: perspective(300px) translateZ(0px) rotateY(1deg);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: perspective(300px) translateZ(0px) rotateY(359deg);
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes fadeIn {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeInFromLeft {
    from {
      opacity: 0.5;
      transform: translateX(-16px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes fadeInFromRight {
    from {
      opacity: 0.5;
      transform: translateX(16px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes smallFadeInFromTop {
    from {
      opacity: 0.5;
      transform: translateY(-4px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
}

@keyframes smallFadeInFromBottom {
    from {
      opacity: 0.5;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
}

@keyframes smallFadeInFromRight {
    from {
      opacity: 0.5;
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes smallFadeInFromLeft {
    from {
      opacity: 0.5;
      transform: translateX(-4px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
}

@keyframes scaleInAddBlock {
    0% {
      opacity: 0;
      transform: scale(0.6) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateX(-50%);
    }
}

@keyframes scaleInLoginBox {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}
