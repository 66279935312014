@import '../../../node_modules/bootstrap/less/bootstrap';
@import '../../../node_modules/font-awesome/less/font-awesome';
@import '../../../node_modules/eonasdan-bootstrap-datetimepicker/src/less/_bootstrap-datetimepicker';

@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');

@import '_variables';
@import '_animations';
@import '_fonts';
@import '_flash';
@import '_bootstrap-extend';
@import '_auth';
@import '_layout';
@import '_files';
@import '_pages';
@import '_styles';
@import '_settings';
@import '_ai';
@import '_apps';
@import '_add-block';
@import '_tree';
@import '_version-history';
@import '_fields';
@import '_image-modal';
@import '_loader';
@import '_context-menu';
@import '_toastr';
@import '_onoff';
@import '_demoaccount';
@import '_corner-ribbon';
@import '_material';

body {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: @tidy-font-size;
    color: @tidy-rich-black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

a {
    color: @tidy-link-color;
    text-decoration: none;

    &:focus {
        color: @tidy-link-color-active;
        text-decoration: none;
    }

    &:hover {
        color: @tidy-link-color-hover;
        text-decoration: none;
    }

}

h1 {
    font-size: 32px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 16px;
}


h2 {
    font-size: 18px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 16px;
}

h3 {
    font-size: @tidy-font-size;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
}

h4 {
    font-size: @tidy-font-size-sm;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
}

.invert {
    background-color: @tidy-rich-black;
    color: white;

    a,
    a:focus,
    .btn-link,
    .btn-link:focus {
        color: @tidy-gray;
    }

    a:hover,
    .btn-link:hover {
        color: white;
    }

    .form-control {
        color: white;
    }

}

.flex-form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;

    .form-group {
        padding-left: 16px;
        padding-right: 16px;
        flex-basis: 100%;

        &.half {
            flex-basis: 50%;
        }

        &.third {
            flex-basis: 33.333333%;
        }

    }
}

.background-grid {
    @grid-color-1: @tidy-light-gray;
    @grid-color-2: fade(@grid-color-1, 75%);
    background-color: @grid-color-2;
    background-image: linear-gradient(45deg, @grid-color-1 25%, transparent 25%, transparent 75%, @grid-color-1 75%, @grid-color-1), linear-gradient(45deg, @grid-color-1 25%, transparent 25%, transparent 75%, @grid-color-1 75%, @grid-color-1);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.talkus-body.talkus-reset .talkus-plugin.talkus-active.talkus-minimized {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.fullscreen-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    text-align: center;

    .btn {
        margin-top: 16px;
        margin-bottom: 16px;
    }

}

.bootstrap-datetimepicker-widget {
    background-color: #ffffff;
}
