@import '_variables';

.version-history {

    ul {
        position: relative;
        margin-bottom: 16px;
        text-transform: uppercase;

        li {
            padding: 8px 0;
            font-size: @tidy-font-size-sm;
            color: @tidy-gray;
            text-align: right;

            &::before {
                position: absolute;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: @tidy-light-gray;
                content: '';
            }

            &.green::before {
                background-color: @tidy-version-green;
            }

        }

    }

}
