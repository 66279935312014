@import '_variables';

/* The ribbons */

@ribbon-size: 200px;
@ribbon-size-small: 150px;

.corner-ribbon {
  width: @ribbon-size;
  background: @tidy-brand;
  position: absolute;
  top: @ribbon-size / 8;
  left: @ribbon-size / -4;
  text-align: center;
  line-height: @ribbon-size / 4;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  &.sticky {
    position: fixed;
  }
  &.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }

  &.top-left {
    top: @ribbon-size / 8;
    left: @ribbon-size / -4;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.top-right {
    top: @ribbon-size / 8;
    right: @ribbon-size / -4;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.bottom-left {
    bottom: @ribbon-size / 8;
    left: @ribbon-size / -4;
    top: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.bottom-right {
    right: @ribbon-size / -4;
    bottom: @ribbon-size / 8;
    top: auto;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.small {
    width: @ribbon-size-small;
    top: @ribbon-size-small / 8;
    left: @ribbon-size-small / -4;
    line-height: @ribbon-size-small / 4;
    letter-spacing: 0;
    font-size: .9em;

    &.top-left {
      top: @ribbon-size-small / 8;
      left: @ribbon-size-small / -4;
    }
    &.top-right {
      top: @ribbon-size-small / 8;
      right: @ribbon-size-small / -4;
      left: auto;
    }
    &.bottom-left {
      bottom: @ribbon-size-small / 8;
      left: @ribbon-size-small / -4;
      top: auto;
    }
    &.bottom-right {
      right: @ribbon-size-small / -4;
      bottom: @ribbon-size-small / 8;
      top: auto;
      left: auto;
    }
  }

  /* Colors */

  &.white {
    background: #f0f0f0;
    color: @text-color;
  }
  &.black {
    background: @tidy-rich-black;
  }
  &.grey {
    background: @tidy-gray;
  }
  &.blue {
    background: @tidy-brand;
  }
  &.green {
    background: @tidy-light-green;
  }
  &.turquoise {
    background: @tidy-flash-color-green;
  }
  &.purple {
    background: @tidy-flash-color-purple;
  }
  &.red {
    background: @tidy-deleted;
  }
  &.orange {
    background: @tidy-unpublished;
  }

}
